import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, TextField } from "@mui/material";
import AlertMessage from "components/AlertMessage";
import { createLocation } from "services/api/gameAPI";
import { countBy } from "lodash";
import AlertConfirm from "components/AlertConfirm";
import { createGameType } from "services/api/gameAPI";
const style = {
  modal: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    bgcolor: "black",
    boxShadow: 24,
    pt: 1,
    pb: 3,
    pr: 4,
    pl: 4,
  },
  card: {
    display: "flex",
    gap: 3,
    width: "100%",
    justifyContent: "space-between",
  },
};

export default function ModalBox(prop) {
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [type, setType] = React.useState("");
  // const [amount, setAmount] = React.useState("");
  const [button, setButton] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const submit = () => {
    setAlertOpen(true);
  };

  React.useEffect(() => {
    if (!type) {
      setButton(true);
    } else {
      setButton(false);
    }
  }, [type]);

  return (
    <div>
      {/* <Fab
        onClick={handleOpen}
        style={{  zIndex: 99,marginBottom:"20px" }}
        size="medium"
        color="primary"
        aria-label="add"
      >
        Create game type
      </Fab> */}
      <Button
        variant="contained"
        sx={{ marginBottom: "20px", textTransform: "none" }}
        onClick={handleOpen}
      >
        Create Game Type
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box container sx={style.modal}>
            <Typography
              sx={{ m: 3 }}
              textAlign="center"
              id="transition-modal-title"
              variant="h4"
              component="h2"
            >
              Add Game Type
            </Typography>
            <Grid container gap={3}>
              <Grid item sm={12} display={"flex"} gap={"10px"} alignItems={"center"}>
                <Typography sx={{ color: "white" }} variant="h6">
                  Pick
                </Typography>
                <TextField
                  onChange={(e) => {
                    const inputValue = Number(e.target.value);
                    if (!isNaN(inputValue) && inputValue > 0) {
                      setType(inputValue);
                    }
                  }}
                  size="small"
                  fullWidth
                  label="Enter No.Of Tokens/Symbols"
                />
              </Grid>

              {/* <Grid item sm={12} >
              <TextField onChange={(e)=> setAmount(e.target.value)} size='small' fullWidth label="amount" />
            </Grid> */}
              <Grid container flexWrap="nowrap" gap={1}></Grid>
            </Grid>

            <MDButton
              disabled={button}
              onClick={submit}
              sx={{ mt: 4 }}
              size="small"
              fullWidth={true}
              variant="gradient"
              color="primary"
            >
              Create
            </MDButton>
          </Box>
        </Fade>
      </Modal>
      {/* <AlertMessage
        open={isOpen}
        // setOpen={setAlertOpen}
        severity={"Successfully"}
        message={"Added"}
      /> */}
      <AlertConfirm
        open={alertOpen}
        title="Create"
        context="Do you want to create a game type?"
        onClose={() => setAlertOpen(!alertOpen)}
        onAccept={() => {
          setAlertOpen(!alertOpen);
          const data = {
            gameType: type,
          };
          console.log("co", data);

          createGameType(data)
            .then((res) => {
              if (res.data.data === "GameType Already Included") {
                alert("Game Type already exist. Please add new one");
                // window.location.reload(false);
              } else {
                alert("Success! New game type added.");
                handleClose();
                window.location.reload(false);
              }
            })

            .catch((err) => {
              if (err?.response?.status === 500) {
                alert("Game type already exist");
              }
            });
        }}
      />
    </div>
  );
}
