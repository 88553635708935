// @mui material components
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // TablePagination,
} from "@mui/material";
import { styled } from "@mui/system";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import ModalBox from "./modal";
import EditModalBox from "./EditModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
}));

function TokenConfig({ configId, paymentTokens, setPaymentTokens }) {
  console.log(paymentTokens);
  // const [tokens, setTokens] = useState([
  //   {
  //     _id: "64664646",
  //     symbol: "USDC",
  //     contractAddress: "0x8FB1E3fC51F3b789dED7557E680551d93Ea9d892",
  //   },
  //   {
  //     _id: "646646888",
  //     symbol: "WETH",
  //     contractAddress: "0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f",
  //   },
  // ]);
  // const [page, setPage] = useState(0);
  // const [limit, setLimit] = useState(5);
  // const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await getAllPayoutStructure({ page: page + 1, limit });
        // console.log(response, "res");

        // setCount(response.data?.data?.totalCount);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  // const handlePageChange = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleLimitChange = (event) => {
  //   setLimit(event.target.value);
  //   setPage(0);
  // };

  return (
    <>
      {loading ? (
        <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>Loading...</div>
      ) : (
        <>
          <Grid container spacing={6} sx={{ marginTop: 3 }}>
            <Grid item xs={12}>
              <Card sx={{ padding: 2 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "12px -1px",
                  }}
                >
                  <MDTypography
                    component="label"
                    style={{
                      padding: "40px",
                      color: "grey",
                      fontWeight: 600,
                    }}
                  >
                    Token Configuration
                  </MDTypography>

                  <div style={{ margin: "20px" }}>
                    <ModalBox configId={configId} />
                  </div>
                </div>
                {paymentTokens?.length === 0 ? (
                  <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>
                    There are no records at the moment.
                  </div>
                ) : (
                  <>
                    <TableContainer>
                      <Table>
                        <StyledTableHead>
                          <TableRow>
                            <StyledTableCell>Symbol</StyledTableCell>
                            <StyledTableCell>Contract Adress</StyledTableCell>

                            <StyledTableCell>Action</StyledTableCell>
                            {/* Add more table headers as needed */}
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {paymentTokens?.map((token, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell>{token?.symbol}</StyledTableCell>
                              <StyledTableCell>{token?.contractAddress}</StyledTableCell>
                              <StyledTableCell>
                                <EditModalBox
                                  configId={configId}
                                  symbol={token?.symbol}
                                  contractAddress={token?.contractAddress}
                                  isEnabled={token?.isEnable}
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </Card>
            </Grid>
            <Grid item xs={12}>
              {/* <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={count}
                rowsPerPage={limit}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
              /> */}
            </Grid>
          </Grid>
          <Footer />
        </>
      )}
    </>
  );
}
export default TokenConfig;
