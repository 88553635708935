import { Grid } from "@mui/material";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import "react-time-picker/dist/TimePicker.css";
import "../../App.css";

import GameCreate from "./GameCreate";
import GameList from "./GameList";

function Games() {
  // const [positions, setPositions] = useState([]);
  // const [count, setCount] = useState(1);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <GameCreate />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <GameList />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Games;
