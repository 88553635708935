import {
  Autocomplete,
  Box,
  Button,
  debounce,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AlertConfirm from "components/AlertConfirm";
import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import TimePicker from "react-time-picker";
import { createGame } from "services/api/gameAPI";
import MultiSelect from "./MultiSelect";
import { calCustomUser } from "services/api/gameAPI";
import InfoIcon from "@mui/icons-material/Info";

const FreeGameForm = ({ gameConfig, cryptos, setOpen }) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [error, setError] = useState({ cryptos: "", positions: "" });
  const [gamedata, setgamedata] = useState(null);
  const [startTimeError, setStartTimeError] = useState(null);
  const [userLimit, setUserLimit] = useState(0);
  const [fupApiError, setFupApiError] = useState(null);
  const [minPlayer, setMinPlayer] = useState(0);

  const [contestIntervalError, setContestIntervalError] = useState(null);
  const [contestInterval, setContestInterval] = useState(0);
  const [gameDuration, setGameDuration] = useState(0);

  const { handleSubmit, control, setValue } = useForm({
    // resolver: yupResolver(ProductSchema),
  });

  const CreateGame = (gameData) => {
    if (gameData.cryptos.length < gameData.gameType) {
      setError({
        ...error,
        cryptos: `Please select atleast ${gameData.gameType} cryptos to continue `,
      });
      return;
    }

    setgamedata(gameData);
    setAlertOpen(true);
  };

  // Debounce the API call to avoid making API calls on every keystroke
  const debouncedCheckUserAvailability = useCallback(
    debounce((per, minPlayer) => {
      handleUserAvailability(per, minPlayer);
    }, 300),
    [] // The empty array ensures the debounce function is created only once
  );

  const handleUserAvailability = async (per, minPlayer) => {
    try {
      setFupApiError(null);

      if (Number(per) <= 0 || Number(minPlayer) <= 0) return;
      if (!per) return false;

      const response = await calCustomUser({
        enteredPercentage: parseInt(per),
        minPlayer: minPlayer,
      });
      const data = response.data;
      console.log(data);
      if (data?.data?.available == false) {
        setFupApiError("Not have enough users to add");
        return false;
      } else {
        setFupApiError(null);
        return true;
      }
    } catch (error) {
      console.error("API call failed:", error);
      setFupApiError("Failed to check user availability");
      return false;
    }
  };

  console.log(startTimeError);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          padding: 3,
          flexWrap: "wrap",
          flexDirection: "column",
          mt: -5,
        }}
        component="form"
        onSubmit={handleSubmit(CreateGame)}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Controller
            name="gameName"
            control={control}
            defaultValue=""
            rules={{ required: "Game name is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="outlined-basic"
                label="Game Name"
                value={value}
                onChange={onChange}
                variant="outlined"
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
          />

          <Controller
            name="gameCategory"
            control={control}
            defaultValue={"Multiplayer"}
            rules={{ required: "Game category is required" }}
            render={({ field: { value }, fieldState: { error } }) => (
              <TextField
                id="outlined-basic"
                label="Game category"
                value={"Multiplayer"}
                // onChange={onChange}
                variant="outlined"
                error={!!error}
                // disabled
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
          />
          <Controller
            name="minPlayer"
            control={control}
            defaultValue={0}
            rules={{ required: "Minimum Players is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="outlined-basic"
                label="Minimum Players"
                value={value}
                onChange={(e) => {
                  onChange(Number(e.target.value) || "");
                  setMinPlayer(Number(e.target.value));
                  debouncedCheckUserAvailability(userLimit, Number(e.target.value));
                }}
                variant="outlined"
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Controller
            name="maxPlayer"
            control={control}
            defaultValue={0}
            rules={{ required: "Maximum Players is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="outlined-basic"
                label="Maximum Players"
                value={value}
                onChange={(e) => {
                  onChange(Number(e.target.value) || "");
                }}
                variant="outlined"
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
          />{" "}
          <Controller
            name="gameFrequency"
            control={control}
            defaultValue={undefined}
            rules={{ required: "Game frequency is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                sx={{ width: 180 }}
                options={
                  gameConfig?.gameFrequency?.map((frequency) => ({
                    label: frequency,
                  })) || []
                }
                getOptionLabel={(option) => option?.label}
                onChange={(event, newValue) => {
                  onChange(newValue?.label || ""); // Use newValue instead of value
                }}
                value={
                  value
                    ? gameConfig?.gameFrequency?.find((option) => option?.label === value)
                    : undefined
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="outlined-basic"
                    label="Game Frequency"
                    variant="outlined"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            )}
          />
          <Controller
            name="gameDuration"
            control={control}
            defaultValue=""
            rules={{ required: "Entry fees is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="outlined-basic"
                label="Game Duration"
                variant="outlined"
                value={value}
                // onChange={(e) => onChange(Number(e.target.value) || "")}
                onChange={(e) => {
                  const interval = Number(e.target.value); //this will take only number
                  onChange(interval || "");
                  setGameDuration(interval);

                  if ((contestInterval ?? 0) < interval) {
                    setContestInterval(interval);
                    setValue("contestInterval", interval);
                    setContestIntervalError(null);
                  }
                }}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
              />
            )}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "18px",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {" "}
          <Controller
            name="gameType"
            control={control}
            defaultValue={undefined}
            rules={{ required: "Game type is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                sx={{ width: 180 }}
                options={
                  gameConfig?.gameType?.map((type) => ({
                    label: type,
                  })) || []
                }
                getOptionLabel={(option) => `pick ${option.label}`}
                onChange={(event, newValue) => {
                  onChange(Number(newValue?.label) || "");
                  //setting type value to state for validating the cryptos count
                }}
                value={
                  value ? gameConfig?.gameType?.find((option) => option.label === value) : undefined
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="outlined-basic"
                    label="Game Type"
                    variant="outlined"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            )}
          />
          <Controller
            name="winnerType"
            control={control}
            defaultValue={undefined}
            rules={{ required: "Winner type is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                sx={{ width: 180 }}
                // getOptionLabel={(option: any) => option.product_name}
                options={
                  gameConfig?.winnerType?.map((category) => ({
                    label: category,
                  })) || []
                }
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  onChange(newValue?.label || ""); // Use newValue instead of value
                }}
                value={
                  value
                    ? gameConfig?.winnerType?.find((option) => option.label === value)
                    : undefined
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="outlined-basic"
                    label="Winner Type"
                    variant="outlined"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            )}
          />
          <Controller
            name="gameGenerate"
            control={control}
            defaultValue={undefined}
            rules={{ required: "Game generate is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                sx={{ width: 180 }}
                options={
                  gameConfig?.gameGenerate?.map((generate) => ({
                    label: generate,
                  })) || []
                }
                getOptionLabel={(option) => option?.label}
                onChange={(event, newValue) => {
                  onChange(newValue?.label || ""); // Use newValue instead of value
                }}
                value={
                  value
                    ? gameConfig?.gameGenerate?.find((option) => option?.label === value)
                    : undefined
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="outlined-basic"
                    label="Game Generate"
                    variant="outlined"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            )}
          />
          <Box
            sx={{
              display: "flex",
              gap: "18px",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <InputLabel sx={{ marginBottom: -1 }}>
                <Tooltip
                  title={
                    "Automatically fills with fake users if actual user participation is below the specified threshold percentage."
                  }
                  placement="top"
                >
                  <InfoIcon sx={{ fontSize: 20, cursor: "pointer" }} />
                </Tooltip>
                {`    User Threshold Auto-Fill`}

                {userLimit == 0 && (
                  <>
                    <br />
                    <Typography fontSize={12} sx={{ ml: 2 }}>
                      {"   Disable Bot Users"}
                    </Typography>
                  </>
                )}

                {userLimit >= 100 && (
                  <>
                    <br />
                    <Typography fontSize={12} sx={{ ml: 2 }}>
                      {"Start the game using bot users"}
                    </Typography>
                  </>
                )}
              </InputLabel>
              <Controller
                name="fup"
                control={control}
                defaultValue={0}
                rules={{ required: "User Threshold Auto-Fill percentage is required" }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                        display: "none",
                      },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                      marginTop: 2,
                      width: 170,
                    }}
                    id="outlined-basic"
                    // label="User Threshold Auto-Fill"
                    variant="outlined"
                    value={value}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography sx={{ color: "#FFFFFF" }}>%</Typography>
                        </InputAdornment>
                      ),
                      inputProps: {
                        max: 100,
                        min: userLimit == 0 ? 0 : 50,
                      },
                    }}
                    type="number"
                    defaultValue={0}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Allow empty input or valid numbers including decimals
                      if (/^\d*\.?\d*$/.test(inputValue)) {
                        onChange(inputValue);
                        // debouncedCheckUserAvailability(inputValue, minPlayer);
                        setUserLimit(inputValue);

                        console.log(parseInt(e?.target?.value ?? 0));

                        if (parseInt(e?.target?.value ?? 0) >= 50) {
                          debouncedCheckUserAvailability(inputValue, minPlayer);
                        } else {
                          if (parseInt(e?.target?.value ?? 0) != 0) {
                            setFupApiError("Value must be greater than or equal to 50");
                          } else {
                            setFupApiError(null);
                          }
                        }
                      }
                    }}
                    error={!!error || !!fupApiError}
                    helperText={error ? error?.message : fupApiError ?? null}
                    autoComplete="off"
                  />
                )}
              />
            </Box>

            <Box
              sx={{
                marginTop: userLimit == 0 || userLimit >= 100 ? 3.8 : 2,
              }}
            >
              <Controller
                name="startTime"
                control={control}
                rules={{ required: "Start time is required" }}
                defaultValue={null}
                render={({ field, fieldState: { error } }) => (
                  <FormControl>
                    <InputLabel sx={{ mt: -3 }}>Start Time</InputLabel>
                    <TimePicker
                      onChange={(value) => {
                        field.onChange(value);
                        setStartTimeError(null); // Reset the error state on value change
                      }}
                      value={field.value}
                      disableClock
                      clearIcon={false}
                      format="h:m a"
                      hourPlaceholder="-"
                      //

                      className={`custom-timepicker ${error ? "error" : ""}`}
                    />

                    <FormHelperText error={!!error} id="my-helper-text">
                      {error?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Box>

            <Box
              sx={{
                marginTop: userLimit == 0 || userLimit >= 100 ? 5 : 3,
              }}
            >
              <Controller
                name="contestInterval"
                control={control}
                defaultValue=""
                rules={{ required: "Contest interval is required" }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    id="outlined-basic"
                    label="Contest Interval"
                    variant="outlined"
                    value={value || contestInterval || ""}
                    type="number"
                    onChange={(e) => {
                      const interval = Number(e.target.value); //this will take only number
                      onChange(interval || "");
                      setContestInterval(interval);

                      if (interval < gameDuration) {
                        setContestIntervalError(
                          "Value must be greater than or equal to Game duration"
                        );
                      } else {
                        setContestIntervalError(null);
                      }
                    }}
                    InputProps={{
                      inputProps: {
                        min: gameDuration ?? 0,
                      },
                    }}
                    error={!!error || !!contestIntervalError}
                    helperText={error ? error.message : contestIntervalError ?? null}
                    autoComplete="off"
                    sx={{
                      "& .MuiFormHelperText-root": {
                        marginLeft: "0px", // Adjust margin to prevent wrapping
                        marginRight: "0px",
                      },
                      width: "11.5rem",
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Typography variant="body2" sx={{ color: "red" }}>
            {error.cryptos ? error.cryptos : ""}
          </Typography>
          <Box mt={2} width={"100%"}>
            <Controller
              name="cryptos"
              control={control}
              defaultValue={null}
              rules={{ required: "Crypto values are required" }}
              render={({ field: { onChange } }) => (
                <MultiSelect
                  items={cryptos}
                  label="Cryptos"
                  selectAllLabel="Select all cryptos"
                  onChange={onChange}
                />
              )}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            type="submit"
            sx={{ textAlign: "center" }}
            // disabled={!isValid}
          >
            Create
          </Button>
        </Box>
      </Box>
      <AlertConfirm
        open={alertOpen}
        title="Create"
        context="Do you want to create the game?"
        onClose={() => setAlertOpen(!alertOpen)}
        onAccept={async () => {
          try {
            const body = {
              ...gamedata,
              fup: parseInt(gamedata?.fup),
              gamePaymentType: "Free",
              entryFees: 0,
            };
            console.log(body, "Body");

            const gameCreate = await createGame(body);
            if (gameCreate) {
              alert("Success! New game has been created");
              setOpen(false);
              setTimeout(() => {
                window.location.reload();
              }, 300);
            }
          } catch (error) {
            console.log(error.response.data?.error, "errr");
            alert(error.response.data?.error);
            setAlertOpen(false);
          }
        }}
      />
    </>
  );
};

export default FreeGameForm;
