import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
// import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";

import { Button, Card, debounce, Grid, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import MDBox from "components/MDBox";
import AlertConfirm from "components/AlertConfirm";
import AlertMessage from "components/AlertMessage";
import { createRules } from "services/api/gameAPI";
import { getByUsername } from "services/api/gameAPI";
import { createUser } from "services/api/gameAPI";
import RHFUploadAvatar from "components/RHTextField/RHFUploadAvatar";

const style = {
  position: "absolute",
  top: { xs: "50%", md: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, sm: 700, lg: 800 },
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  height: { xs: 400, md: 380 },
  overflowY: "scroll",
};

export default function CreateCustomUser() {
  const [isAlertMessgOpen, setIsAlertMessg] = React.useState(false);

  const [alertOpen, setAlertOpen] = React.useState(false);
  const submit = () => {
    setAlertOpen(true);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = async () => {
    try {
      setOpen(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleClose = () => setOpen(false);

  const [username, setUsername] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [usernameError, setUsernameError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");

  const [file, setFile] = React.useState(null);

  const [data, setData] = React.useState();

  // Function to call the API and check if the username exists
  const checkUsername = async (username) => {
    try {
      if (!username) {
        setUsernameError(false);
        setHelperText("");
        return;
      }

      const response = await getByUsername(username);
      console.log(response.data);
      if (response?.data?.data?.isExist) {
        setUsernameError(true);
        setHelperText("Username already exists");
      } else {
        setUsernameError(false);
        setHelperText("");
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error) {
        setUsernameError(true);
        setHelperText(error?.response?.data?.error);
      } else {
        setUsernameError(false);
        setHelperText("");
      }
    }
  };

  // Debounce the API call to avoid making API calls on every keystroke
  const debouncedCheckUsername = React.useCallback(
    debounce((username) => {
      checkUsername(username);
    }, 300),
    [] // The empty array ensures the debounce function is created only once
  );

  // Handle input change
  const handleChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    const lowercaseOnly = inputValue.replace(/[^a-z]/g, "");
    setUsername(lowercaseOnly);
    debouncedCheckUsername(lowercaseOnly);
  };

  React.useEffect(() => {
    setData({
      username: username,
      name: firstName,
      loginType: "MetaMask",
      profilePic: file,
    });
  }, [username, firstName, file]);

  return (
    <>
      <Button
        variant="contained"
        sx={{ marginBottom: "20px", textTransform: "none" }}
        onClick={handleOpen}
      >
        Create
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Box container sx={style.modal}>
            <Box sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "20px",
                      padding: 3,
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{ m: 2 }}
                        textAlign="center"
                        id="transition-modal-title"
                        variant="h4"
                        component="h2"
                      >
                        Create User
                      </Typography>
                      <RHFUploadAvatar file={file} setFile={setFile} />
                      {/* <ProfilePictureUpload file={file} setFile={setFile} /> */}

                      <MDBox>
                        <Stack
                          width={{ sm: "300px", md: "500px" }}
                          marginBottom={3}
                          gap={2}
                          justifyContent={"start"}
                        >
                          <TextField
                            value={username}
                            type="text"
                            // onChange={(e) => setUsername(e.target.value)}
                            onChange={(e) => handleChange(e)}
                            label="User name"
                            error={usernameError}
                            helperText={helperText}
                          />

                          <TextField
                            value={firstName}
                            type="text"
                            onChange={(e) => setFirstName(e.target.value)}
                            label="First name"
                          />

                          <MDButton
                            type="submit"
                            onClick={submit}
                            sx={{ textAlign: "center" }}
                            variant="gradient"
                            color="primary"
                          >
                            Create
                          </MDButton>
                        </Stack>{" "}
                      </MDBox>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <AlertConfirm
              open={alertOpen}
              title="Create"
              context="Would you like to add a custom user?"
              onClose={() => setAlertOpen(!alertOpen)}
              onAccept={async () => {
                try {
                  console.log(data);
                  console.log(data?.profilePic);
                  if (!username || !firstName) {
                    return alert("Username or first name should not be empty");
                  }

                  const formData = new FormData();
                  formData.append("name", data.name);
                  formData.append("username", data.username);
                  formData.append("loginType", "MetaMask");
                  formData.append("profilePic", data.profilePic);
                  // console.log(formData, "FormData");
                  const res = await createUser(formData);
                  console.log(res);
                  setAlertOpen(false);
                  alert("User added successfully!");
                  setOpen(false);
                  setTimeout(() => {
                    window.location.reload();
                  }, 300);
                } catch (error) {
                  console.log(error);
                  alert("Something went wrong!");
                }
              }}
            />

            <AlertMessage
              open={isAlertMessgOpen}
              setOpen={setIsAlertMessg}
              severity="Error"
              message="Something went wrong!"
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
