/**
=========================================================
* Crypto Champs React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { fetchCryptosData } from "services/api/gameAPI";

import MDTypography from "components/MDTypography";
import { addCryptoData } from "services/api/gameAPI";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableHead = styled(TableHead)(() => ({
  display: "table-header-group",
}));

function AddCryptos() {
  const [cryptos, setCryptos] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [term, setTerm] = useState("");
  const [query, setQuery] = useState("");

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      let response = [];
      try {
        response = await fetchCryptosData({ page: page + 1, limit, term });
        setCryptos(response.data.data.cryptos);
        setCount(response.data.data.totalCount);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [page, limit, term]);

  console.log(cryptos, "RES");

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };
  const handleTitle = async (e) => {
    const newQuery = e.target.value;

    setTerm(newQuery);
    setQuery(newQuery);
    console.log(query);
  };

  const handleAddCryptos = async (
    coinId,
    name,
    symbol,
    price,
    price_change_percentage_24h,
    type,
    priceType,
    binanceImageUrl,
    isAdminSelected
  ) => {
    const data = {
      coinId,
      name,
      symbol,
      price,
      price_change_percentage_24h,
      type,
      priceType,
      binanceImageUrl,
      isAdminSelected,
    };

    console.log(data);

    try {
      const res = await addCryptoData(data);
      if (res) alert("Crypto added successfully..!");
    } catch (error) {
      console.log(error);
      alert("somthing went wrong.");
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {loading ? (
        <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>Loading...</div>
      ) : (
        <>
          <Grid container spacing={6}>
            <>
              <Grid item xs={12}>
                <Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "12px -1px",
                    }}
                  >
                    <MDBox margin="20px" pr={1}>
                      <MDInput label="Search cryptos" value={query} onChange={handleTitle} />
                    </MDBox>

                    <MDTypography component="label" style={{ marginRight: "120px" }}></MDTypography>
                  </div>
                  {/* {query === "" ? (
                      ""
                    ) : ( */}
                  {/* <div id="search-card" className={classes.searchCard}>
                      <Paper>
                        <List id="suggestion-list">
                          {suggestions.map((suggestion) => (
                            <ListItem
                              key={suggestion._id}
                              button
                              className={classes.suggestionItem}
                              // onClick={() => handleSuggestionClick(suggestion)}
                            >
                              {suggestion?.name}
                            </ListItem>
                          ))}
                        </List>
                      </Paper>
                    </div> */}
                  {/* )} */}

                  <TableContainer>
                    <Table>
                      <StyledTableHead>
                        <TableRow>
                          <StyledTableCell>Name</StyledTableCell>
                          <StyledTableCell>Symbol</StyledTableCell>
                          <StyledTableCell> Price</StyledTableCell>
                          {/* <StyledTableCell>Price Change Percentage 24h</StyledTableCell> */}
                          <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                      </StyledTableHead>
                      {cryptos.length === 0 ? (
                        <div
                          style={{
                            color: "#FFF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "300px",
                          }}
                        >
                          There are no records at the moment.
                        </div>
                      ) : (
                        <TableBody>
                          {cryptos?.map(
                            (crypto) =>
                              crypto.isAdded === false && (
                                <StyledTableRow key={crypto.coinId}>
                                  <StyledTableCell
                                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                                  >
                                    {/* <Avatar src={crypto?.binanceImageUrl} /> */}
                                    {crypto.name}
                                  </StyledTableCell>
                                  <StyledTableCell>{crypto.symbol}</StyledTableCell>
                                  <StyledTableCell>{crypto.price}</StyledTableCell>
                                  {/* <StyledTableCell>
                                {crypto.price_change_percentage_24h}
                              </StyledTableCell> */}
                                  <StyledTableCell>
                                    <MDButton
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        handleAddCryptos(
                                          crypto.coinId,
                                          crypto.name,
                                          crypto.symbol,
                                          crypto.price,
                                          crypto.price_change_percentage_24h,
                                          crypto.type,
                                          crypto.priceType,
                                          crypto.binanceImageUrl,
                                          crypto.isAdminSelected
                                        )
                                      }
                                    >
                                      Add
                                    </MDButton>
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                          )}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={count}
                  style={{ color: "white" }}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                />
              </Grid>
            </>
          </Grid>
          <Footer />
        </>
      )}
    </DashboardLayout>
  );
}
export default AddCryptos;
