/**
=========================================================
* Crypto Champs React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
// Crypto Champs React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Cookies from "js-cookie";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import { IconButton, InputAdornment } from "@mui/material";
import Logo from "./favicon.png";
import { logInModule } from "services/api/gameAPI";

function SignIn() {
  Cookies.remove("token");
  sessionStorage.removeItem("permissions");
  sessionStorage.removeItem("username");
  localStorage.removeItem("access");
  const navigate = useNavigate();
  // const [rememberMe, setRememberMe] = useState(false);
  const [userName, setUserName] = useState("");
  const [Password, setPassword] = useState("");
  // const [access, setAccess] = useState(null);
  // const [token, setToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleSignIn = (e) => {
    e.preventDefault();

    const data = {
      username: userName,
      password: Password,
    };

    logInModule(data)
      .then((response) => {
        // localStorage.setItem("access", JSON.stringify(response.data.access_token));
        Cookies.set("token", response.data.access_token);
        sessionStorage.setItem("token", response.data.access_token);
        sessionStorage.setItem("username", response.data.username);
        sessionStorage.setItem("permissions", response.data.permissions);
        navigate("/dashboard");
      })
      .catch((error) => {
        console.log(error);
        document.getElementById("error").innerHTML = "Wrong Login Credential";
        alert("Incorrect username or password. Please try again.");
      });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            <img src={Logo} alt="logo" />
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                // type="email"
                label="Username"
                onChange={(e) => setUserName(e.target.value)}
                fullWidth
                autoComplete="off"
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type={showPassword ? "text" : "password"}
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}></MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="dark" onClick={handleSignIn} fullWidth>
                sign in
              </MDButton>
              <MDTypography
                display="inline"
                variant="h6"
                textTransform="capitalize"
                fontWeight="small"
                id="error"
                sx={{ color: "red" }}
              ></MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
