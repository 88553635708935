import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { CircularProgress, alpha } from "@mui/material";
import PropTypes from "prop-types";
import MDButton from "components/MDButton";
import LoadingButton from "@mui/lab/LoadingButton";

export default function AlertConfirm({
  open,
  title,
  context,
  onClose,
  onAccept,
  get,
  loading,
  subText,
  isSubmitting,
}) {
  const handleAccept = async () => {
    await onAccept();
    if (get) await get();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ backgroundColor: "#00000" }}
    >
      <DialogTitle color="primary" id="alert-dialog-title">
        {context}
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="dark" id="alert-dialog-description">
          <span style={{ color: "black", fontSize: "12px" }}>{subText}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {loading === true ? (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <CircularProgress color="secondary" />
            </Grid>
          </Grid>
        ) : (
          <>
            <MDButton variant="gradient" color="error" onClick={handleClose} size="small">
              NO
            </MDButton>
            {/* <MDButton variant="gradient" color="success" onClick={handleAccept} size="small">
              YES
            </MDButton> */}
            <LoadingButton
              loading={isSubmitting}
              loadingIndicator="Loading..."
              sx={{
                backgroundColor: "#58B112",
                textTransform: "none",
                paddingX: 2,
                borderRadius: "8px",
                color: "#ffffff", // Ensure the color is set to white
                // "& .MuiLoadingButton-label": {
                //   color: "#000000", // Target the inner label for the text color
                // },
                "&:hover": {
                  backgroundColor: "#58B112",
                  color: "#000000",
                },
              }}
              onClick={handleAccept}
              size="small"
            >
              YES
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

AlertConfirm.defaultProps = {
  title: "Alert",
  context: "Context",
  get: () => {},
};

AlertConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  context: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  get: PropTypes.func,
  loading: PropTypes.bool.isRequired,
};
