import { ethers } from "ethers";
import USDCToken from "../../Contract/USDC.json";
import { createBlockchainTransaction } from "services/api/gameAPI";

const adminTransaction = async (
  usdcContractAddress,
  profit,
  destination,
  gameInstanceId,
  transactionType
) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();
    const usdcContract = new ethers.Contract(usdcContractAddress, USDCToken, signer);

    const amount = ethers.utils.parseUnits(`${profit}`, 18);

    const transactionToPersonalWallet = await usdcContract.transfer(destination, amount, {
      gasLimit: 300000,
    });
    const receipt = await transactionToPersonalWallet.wait();
    // console.log(receipt);
    if (receipt?.transactionHash && receipt?.status == 1) {
      const dataOne = {
        walletAddress: destination,
        token: profit,
        paymentMethod: "USDC",
        transactionType: transactionType,
        gameInstanceId: gameInstanceId,
        exchangedToken: profit,
        transactionHash: receipt?.transactionHash,
      };

      const createTransaction = await createBlockchainTransaction(dataOne);
      console.log(createTransaction);
      if (transactionType === "TreasuryWalletWithdrawal") {
        alert("Token withdrawal to treasury wallet successfully!");
      } else {
        alert("Token withdrawal to personal wallet successfully!");
      }
      setTimeout(() => {
        window.location.reload();
      }, 300);
      return createTransaction;
    }
  } catch (error) {
    throw error;
  }
};

export default adminTransaction;
