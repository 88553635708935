// import PropTypes from "prop-types";
// // form
// import { useFormContext } from "react-hook-form";
// // @mui
// import { FormHelperText } from "@mui/material";
// import UploadAvatar from "components/upload/UploadAvatar";
// //

// // ----------------------------------------------------------------------

// RHFUploadAvatar.propTypes = {
//   name: PropTypes.string,
// };

// export function RHFUploadAvatar({ name, ...other }) {
//   // const { control } = useFormContext();

//   return (
//     // <Controller
//     //   name={name}
//     //   control={control}
//     //   render={({ field, fieldState: { error } }) => {
//     //     const checkError = !!error && !field.value;

//     //     return (
//     <div>
//       <UploadAvatar
//         // accept="image/*"
//         error={"checkError"}
//         {...other}
//         // file={"hah"}
//       />
//       {/* {checkError && (
//         <FormHelperText error sx={{ px: 2, textAlign: "center" }}>
//           {"error.message"}
//         </FormHelperText>
//       )} */}
//     </div>
//     //   );
//     // }}
//     // />
//   );
// }

// // ----------------------------------------------------------------------

import React, { useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import UploadAvatar from "components/upload/UploadAvatar";

const RHFUploadAvatar = ({ file, setFile, networkImage }) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setFile(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        })
      );
      setError(false);
      setHelperText("");
    } else {
      setError(true);
      setHelperText("Please upload a valid file.");
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      setFile(newFile);
      setError(false);
      setHelperText("");
    } else {
      setError(true);
      setHelperText("Please upload a valid file.");
    }
  }, []);

  return (
    <Box flexDirection={"row"}>
      {/* <Typography variant="h6" gutterBottom>
        Upload Your Avatar
      </Typography> */}
      <UploadAvatar
        file={file}
        error={error}
        helperText={helperText}
        onDrop={onDrop}
        accept="image/*" // Specify accepted file types
        networkImage={networkImage}
      />
    </Box>
  );
};

export default RHFUploadAvatar;
