import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import {
  Card,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Grid,
  Checkbox,
  TableCell,
  TextField,
} from "@mui/material";
// //import { BASE_URL , UN_AUTH_STATUS } from "../../config";
import permissionsData from "./permissionsData";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useNavigate } from "react-router-dom";
import { createRole } from "services/api/gameAPI";
import AlertConfirm from "components/AlertConfirm";

permissionsData.sort((a, b) => a.action.length - b.action.length);

let headerAction = [
  { name: "view", value: "read" },
  { name: "add", value: "write" },
  { name: "edit", value: "edit" },
  { name: "delete", value: "delete" },
  { name: "block", value: "block" },
];

function RoleBasedAccessControl() {
  const [userId, setUserId] = useState(null);
  let [alertOpen, setAlertOpen] = useState(false);
  const [roleName, setRoleName] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();

  const handlePermissionChange = (permissionName, action) => {
    setPermissions((prevPermissions) => {
      const existingPermissionIndex = prevPermissions.findIndex(
        (p) => p.permissionName === permissionName
      );

      if (existingPermissionIndex !== -1) {
        return prevPermissions.map((permission, index) => {
          if (index === existingPermissionIndex) {
            const actions = permission.actions.includes(action)
              ? permission.actions.filter((a) => a !== action)
              : [...permission.actions, action];

            return {
              ...permission,
              actions,
            };
          }
          return permission;
        });
      } else {
        // Create a new permission object if it doesn't exist
        return [
          ...prevPermissions,
          {
            permissionName,
            actions: [action],
          },
        ];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      role: roleName,
      permissions: [],
    };
    permissions.forEach((permission) => {
      permission.actions.forEach((action) => {
        const permissionText = `${permission.permissionName}.${action}`;
        payload.permissions.push(permissionText.trim());
      });
    });

    //console.log(payload);
    await setUserId(payload);
    setAlertOpen(true);
  };

  const handleSelectAll = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);
    setPermissions((prevPermissions) => {
      if (!selectAll) {
        // Select all checkboxes
        const updatedPermissions = permissionsData.map((permission) => ({
          permissionName: permission.name,
          actions: headerAction.map((action) => action.value),
        }));
        return updatedPermissions;
      } else {
        // Unselect all checkboxes
        return [];
      }
    });
  };
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Grid container justifyContent="flex-end" style={{ marginBottom: "10px" }}>
          <Button variant="contained" onClick={handleSelectAll}>
            {selectAll ? "Unselect All" : "Select All"}
          </Button>
        </Grid>

        <form onSubmit={(e) => handleSubmit(e)}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label style={{ color: "white", margin: "12px" }}>Role name</label>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                variant="outlined"
                fullWidth
                required
                placeholder="Input role name"
                onChange={(e) => setRoleName(e.target.value)}
              />
            </div>
          </div>

          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              margin: "40px 0px",
            }}
          >
            <Table>
              <TableHead style={{ display: "table-header-group" }}>
                <TableRow>
                  <TableCell style={{ color: "white" }}> Functionalities/Name</TableCell>

                  {headerAction.map((e) => (
                    <TableCell style={{ color: "white" }} key={e?.name}>
                      <p>{e.name && e.name.charAt(0).toUpperCase() + e.name.slice(1)}</p>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {permissionsData.map((p, index) => (
                  <TableRow key={`${p._id}-${index}`}>
                    <TableCell style={{ color: "white" }}>{p.name}</TableCell>
                    {headerAction?.map((e) => (
                      <TableCell key={e?.value}>
                        <div
                          key={`${p._id}-${e.value}`}
                          style={{ display: p.action.includes(e.value) ? "flex" : "none" }}
                        >
                          <Checkbox
                            checked={
                              permissions?.findIndex(
                                (pe) => pe.permissionName === p.name && pe.actions.includes(e.value)
                              ) !== -1
                            }
                            onChange={() => handlePermissionChange(p.name, e.value)}
                          />
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Button variant="contained" color="primary" type="submit" style={{ margin: "20px" }}>
              Save
            </Button>
          </div>
        </form>
      </div>
      <AlertConfirm
        open={alertOpen}
        title="Delete"
        context="Do you want to create new role ?"
        onClose={() => setAlertOpen(!alertOpen)}
        onAccept={async () => {
          try {
            const responseMessage = await createRole(userId);
            console.log(responseMessage);
            navigate("/Role");
          } catch (error) {
            alert(error.message);
          }
        }}
      />
    </DashboardLayout>
  );
}

export default RoleBasedAccessControl;
