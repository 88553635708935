import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import GridViewIcon from "@mui/icons-material/GridView";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getRole } from "services/api/gameAPI";
import { Table,TableCell,TableRow,TableContainer,TableHead,TableBody } from "@mui/material";
import { styled } from "@mui/system";
import AlertConfirm from "components/AlertConfirm";
import { deleteRole } from "services/api/gameAPI";
import MDButton from "components/MDButton";
import { UN_AUTH_STATUS } from "lib/axiosConfig";
const RoleAccessTable = () => {
  const [userId, setUserId] = useState("");
  let [alertOpen, setAlertOpen] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    fetchPermissions();
  }, []);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: theme.palette.common.white,
    borderBottom: "none",
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  }));
  
  const StyledTableHead = styled(TableHead)(() => ({
    display: "table-header-group",
  }));
  const fetchPermissions = async () => {
    try {
      setLoading(false);
      const response = await getRole();
      setPermissions(response.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching permissions:", error);
      alert(`${UN_AUTH_STATUS}`);
      window.history.back();
    }
  };

  const handleCreateNewRole = () => {
    navigate('/Role/New');
  };
  const userHandle = (id) => {
    setAlertOpen(true);
    setUserId(id);
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <div className="d-flex justify-content-center">
        <MDButton
         variant="outlined"
          style={{ margin: "20px 10px 20px 0", width: "150px" }}
          onClick={handleCreateNewRole}
        >
          Create New Role
        </MDButton>
      </div>
      <div className="d-flex justify-content-center">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div>
            {permissions?.length == 0 ? (
              <div className="text-center">
                <br />
                There is no user role
              </div>
              ) : (
                <TableContainer>
              <Table style={{ minWidth: "600px" }}>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCell>Functionalities/Name</StyledTableCell>
                    {/* <Th>Permission </Th> */}
                    <StyledTableCell>View</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {permissions?.map((permission) => (
                    <StyledTableRow key={permission._id}>
                      <StyledTableCell>{permission.role}</StyledTableCell>

                      <StyledTableCell>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm rounded-0"
                          onClick={() => {
                            navigate(`/Role/View/${permission._id}`)
                          }}
                        >
                          <GridViewIcon />
                        </button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <button
                          type="button"
                          className="btn btn-warning btn-sm rounded-0"
                          onClick={() => userHandle(permission._id)}
                        >
                          <DeleteIcon />
                        </button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
                    </Table>
                    </TableContainer>
            )}
          </div>
        )}
      </div>
      <AlertConfirm
        open={alertOpen}
        title="Delete"
        context="Do you want to delete ?"
        onClose={() => setAlertOpen(!alertOpen)}
        onAccept={async () => {
          try {
            const response = await deleteRole(userId);
            console.log(response.data);
            window.location.reload(false);
          } catch (error) {
            console.error("An error occurred:", error);
          }
        }}
      />
    </DashboardLayout>
  );
};

export default RoleAccessTable;
