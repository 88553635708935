import styled from "@emotion/styled";
import {
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DeleteIcon from "@mui/icons-material/Delete";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import GameRuleCreateModal from "./createModal";
import GameRuleCreateModalLoser from "./createModalLooser";
import { getBiggestWinnerRules } from "services/api/gameAPI";
import { getBiggestLoserRules } from "services/api/gameAPI";
import EditRuleModal from "./EditRuleModal";
import { Box } from "@mui/system";
import { deleteRule } from "services/api/gameAPI";
import AlertConfirm from "components/AlertConfirm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
}));

const GameRules = () => {
  const [biggestWinnerRules, setBiggestWinnerRules] = useState([]);
  const [biggestLooserRules, setBiggestLooserRules] = useState([]);

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [ruleId, setRuleId] = React.useState();

  const handleRuleDelete = (id) => {
    setRuleId(id);
    setAlertOpen(true);
  };

  const fetchBiggestWinnerGameRules = async () => {
    try {
      const response = await getBiggestWinnerRules();
      console.log(response.data?.data);
      const data = response.data?.data;
      const res = await getBiggestLoserRules();
      const dataLoser = res.data?.data;
      setBiggestLooserRules(dataLoser);
      setBiggestWinnerRules(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBiggestWinnerGameRules();
  }, []);

  console.log(biggestWinnerRules, "BWR");

  console.log(biggestLooserRules, "BLR");

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <>
        <Grid container spacing={6} sx={{ marginTop: 3 }}>
          <Grid item xs={12}>
            <Card sx={{ padding: 2 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "12px -1px",
                }}
              >
                <MDTypography
                  component="label"
                  style={{
                    padding: "40px",
                    color: "grey",
                    fontWeight: 600,
                  }}
                >
                  Biggest Winner Rules
                </MDTypography>

                <div style={{ margin: "20px" }}>
                  <GameRuleCreateModal />
                </div>
              </div>

              <TableContainer>
                <Table>
                  <StyledTableHead>
                    <TableRow>
                      <StyledTableCell>Position</StyledTableCell>
                      <StyledTableCell>Rules</StyledTableCell>

                      <StyledTableCell></StyledTableCell>

                      {/* Add more table headers as needed */}
                    </TableRow>
                  </StyledTableHead>
                  {biggestWinnerRules?.length === 0 ? (
                    <div
                      style={{
                        color: "#FFF",
                        paddingLeft: "35%",
                        paddingBottom: "10%",
                        marginTop: 30,
                      }}
                    >
                      There are no records at the moment.
                    </div>
                  ) : (
                    <>
                      <TableBody>
                        {biggestWinnerRules?.map((win, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell>{win?.position}</StyledTableCell>
                            <StyledTableCell>{win?.step}</StyledTableCell>

                            <StyledTableCell>
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                gap={2}
                              >
                                <EditRuleModal
                                  rules={win}
                                  //   configId={configId}
                                  // symbol={token?.symbol}
                                  // contractAddress={token?.contractAddress}
                                  // isEnabled={token?.isEnable}
                                />

                                <IconButton
                                  aria-label="delete"
                                  color="error"
                                  sx={{ marginBottom: 2 }}
                                  onClick={() => handleRuleDelete(win?._id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </>
                  )}
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>

        {/* Looser */}

        <Grid container spacing={6} sx={{ marginY: 3 }}>
          <Grid item xs={12}>
            <Card sx={{ padding: 2 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "12px -1px",
                }}
              >
                <MDTypography
                  component="label"
                  style={{
                    padding: "40px",
                    color: "grey",
                    fontWeight: 600,
                  }}
                >
                  Biggest Looser Rules
                </MDTypography>

                <div style={{ margin: "20px" }}>
                  <GameRuleCreateModalLoser />
                </div>
              </div>

              <TableContainer>
                <Table>
                  <StyledTableHead>
                    <TableRow>
                      <StyledTableCell>Position</StyledTableCell>
                      <StyledTableCell>Rules</StyledTableCell>

                      <StyledTableCell></StyledTableCell>

                      {/* Add more table headers as needed */}
                    </TableRow>
                  </StyledTableHead>
                  {biggestLooserRules.length === 0 ? (
                    <div
                      style={{
                        color: "#FFF",
                        paddingLeft: "35%",
                        paddingBottom: "10%",
                        marginTop: 30,
                      }}
                    >
                      There are no records at the moment.
                    </div>
                  ) : (
                    <>
                      <TableBody>
                        {biggestLooserRules?.map((loss, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell>{loss?.position}</StyledTableCell>
                            <StyledTableCell>{loss?.step}</StyledTableCell>

                            <StyledTableCell>
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                gap={2}
                              >
                                <EditRuleModal rules={loss} />

                                <IconButton
                                  aria-label="delete"
                                  color="error"
                                  sx={{ marginBottom: 2 }}
                                  onClick={() => handleRuleDelete(loss?._id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </>
                  )}
                </Table>
              </TableContainer>
            </Card>

            <AlertConfirm
              open={alertOpen}
              title="Delete"
              context="Do you want to delete rule ?"
              onClose={() => setAlertOpen(!alertOpen)}
              onAccept={async () => {
                try {
                  const res = await deleteRule(ruleId);
                  if (res) {
                    alert("Rule deleted successfull");
                    setTimeout(() => {
                      window.location.reload();
                    }, 300);
                  }
                } catch (error) {
                  console.log(error);
                  alert("Something went wrong!");
                }
              }}
            />
          </Grid>
        </Grid>
        <Footer />
      </>
    </DashboardLayout>
  );
};

export default GameRules;
