import {
  Avatar,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import AlertConfirm from "components/AlertConfirm";
import BackButton from "components/BackButton/BackButton";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { removeInactiveCryptos } from "services/api/gameAPI";
import { getActiveAndInActieCryptos } from "services/api/gameAPI";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableHead = styled(TableHead)(() => ({
  display: "table-header-group",
}));

const ShowNewUpdated = () => {
  const [cryptos, setCryptos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [cryptoId, setCryptoId] = useState();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      let response = [];
      try {
        response = await getActiveAndInActieCryptos({ isActive: false, page: page + 1, limit });
        console.log(response.data, "dd");
        setCryptos(response.data?.data?.data);
        setCount(response.data?.data?.totalCount);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [limit, page]);

  const submit = (id) => {
    setAlertOpen(true);
    setCryptoId(id);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  console.log(cryptos, "RES");
  return (
    <>
      <DashboardLayout>
        {/* <DashboardNavbar /> */}
        <BackButton />

        <Grid container spacing={6}>
          <>
            <Grid item xs={12}>
              <Card sx={{ paddingY: 2, marginTop: 2 }}>
                <TableContainer>
                  <Table>
                    {loading ? (
                      <>
                        <Typography
                          sx={{
                            color: "#fff",
                            textAlign: "center",
                            paddingY: 10,
                          }}
                        >
                          Loading...
                        </Typography>
                      </>
                    ) : cryptos?.length === 0 ? (
                      <Typography
                        sx={{
                          color: "#fff",
                          textAlign: "center",
                          paddingY: 10,
                        }}
                      >
                        There are no records at the moment.
                      </Typography>
                    ) : (
                      <>
                        <StyledTableHead>
                          <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Symbol</StyledTableCell>
                            <StyledTableCell> Price</StyledTableCell>
                            <StyledTableCell>Open 24h</StyledTableCell>
                            {/* <StyledTableCell>Price Change Percentage 24hr</StyledTableCell> */}
                            <StyledTableCell>Action</StyledTableCell>
                          </TableRow>
                        </StyledTableHead>

                        <TableBody>
                          {cryptos?.map((crypto) => (
                            <StyledTableRow key={crypto._id}>
                              <StyledTableCell
                                sx={{ display: "flex", alignItems: "center", gap: 1 }}
                              >
                                <Avatar src={crypto.image} />
                                {crypto.name}
                              </StyledTableCell>
                              <StyledTableCell>{crypto.symbol}</StyledTableCell>
                              <StyledTableCell>${crypto.price}</StyledTableCell>
                              <StyledTableCell>${crypto.open_24h}</StyledTableCell>
                              {/* <StyledTableCell>{crypto.price_change_percentage_24h}%</StyledTableCell> */}
                              <StyledTableCell>
                                <MDButton color="primary" onClick={() => submit(crypto._id)}>
                                  Enable
                                </MDButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </>
                    )}
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={count}
                labelRowsPerPage="Rows per page:"
                style={{ color: "white" }} // Change the color here
                rowsPerPage={limit}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
              />
            </Grid>
          </>
        </Grid>
        <AlertConfirm
          open={alertOpen}
          title="Create"
          context="Do you want enable crypto?"
          onClose={() => setAlertOpen(!alertOpen)}
          onAccept={async () => {
            try {
              const response = await removeInactiveCryptos({ id: cryptoId });
              if (response.data) {
                alert("Crypto enabled successfully");
                setTimeout(() => {
                  window.location.reload();
                }, 300);
              }
            } catch (error) {
              console.log(error);
              alert("Something went wrong");
            }
          }}
        />
      </DashboardLayout>
    </>
  );
};

export default ShowNewUpdated;
