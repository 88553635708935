import {
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getClosedGameInstance } from "services/api/gameAPI";
import { ethers } from "ethers";
import USDCToken from "../../Contract/USDC.json";
import newAbi from "../../Contract/NEW.json";
import UniswapV2Router from "../../Contract/SWAP_ABI.json";

import { styled } from "@mui/system";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getBlockChianConfig } from "services/api/gameAPI";
import { createBlockchainTransaction } from "services/api/gameAPI";
import MDBadge from "components/MDBadge";

import UNISWAP from "@uniswap/sdk";
import { getAddress } from "ethers/lib/utils";
import { Token, WETH, Fetcher, Route, Trade, TokenAmount, TradeType, Percent } from "@uniswap/sdk";
import { useWeb3React } from "@web3-react/core";
import adminTransaction from "./adminTransaction";

//
// import { Route, Token, TokenAmount, Trade, TradeType, Fetcher, Pair, Percent } from "uniswap-sdk";
// import { Route, Token, TokenAmount, Trade, TradeType, CurrencyAmount, Percent } from "uniswap-v2";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
}));

const AdminWithdrawal = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const [data, setData] = useState([]);
  const [usdcBalance, setUsdcBalance] = useState();
  const [wethBalance, setWethBalance] = useState();

  const [adminWalletAddress, setAdminWalletAddress] = useState();
  const [usdcContractAddress, setUsdcContractAddress] = useState();
  const [wethContractAddress, setWethContractAddress] = useState();
  const [personalWallet, setPersonalWallet] = useState();
  const [treasuryWallet, setTreasuryWallet] = useState();
  const [wethToUsdcAmount, setWethToUsdcAmount] = useState();
  const [usdcToWethTransferAmount, setUsdcToWethTransferAmount] = useState();

  const [personalWalPercentage, setPersonalWalPercentage] = useState();
  const [treasuryWalPercentage, setTreasuryWalPercentage] = useState();
  const [adminProfitPercentage, setAdminProfitPercentage] = useState();

  // https://chainlist.org/?search=arbitrum
  const ARBITRUM_MAINNET_JSON_RPC_URL = "https://arbitrum.llamarpc.com";
  // https://docs.camelot.exchange/contracts/amm-v2/router
  const CAMELOT_ROUTER_ADDRESS_ARBITRUM_MAINNET = "0xc873fEcbd354f5A56E00E710B90EF4201db2448d";
  // https://arbiscan.io/token/0x82af49447d8a07e3bd95bd0d56f35241523fbab1
  const WETH_ARBITRUM_MAINNET = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
  // https://arbiscan.io/token/0xff970a61a04b1ca14834a43f5de4533ebddb5cc8
  const USDCe_ARBITRUM_MAINNET = "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8";

  const uniswapRouterAddress = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";

  const path = [WETH_ARBITRUM_MAINNET, USDCe_ARBITRUM_MAINNET];

  console.log(usdcContractAddress);

  useEffect(() => {
    setLoading(true);
    const fetchClosedGameInstace = async () => {
      try {
        const response = await getClosedGameInstance({ page: page + 1, limit });
        setData(response.data?.data?.gameInstance);
        setCount(response.data?.data?.totalCount);
        const res = await getBlockChianConfig();
        console.log(res);

        setAdminWalletAddress(res.data?.adminWallet);
        setUsdcContractAddress(res.data?.paymentMethod?.find((item) => item.symbol === "USDC"));
        setWethContractAddress(res.data?.paymentMethod?.find((item) => item.symbol === "WETH"));
        setPersonalWallet(res.data?.personalWalletAddress);
        setTreasuryWallet(res.data?.treasuryWalletAddress);

        setTreasuryWalPercentage(res.data?.treasuryWalPct);
        setPersonalWalPercentage(res.data?.personalWalPct);
        setAdminProfitPercentage(res.data?.adminProfitPercentage);

        console.log(res.data, "WC");
        return response.data;
      } catch (error) {
        alert("Something went wrong");
        console.log(error);
      }
    };
    fetchClosedGameInstace();

    setLoading(false);
  }, [limit, page]);

  console.log(data);

  const getAdminWalletBalace = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();
    const usdcContract = new ethers.Contract(
      usdcContractAddress?.contractAddress,
      USDCToken,
      signer
    );
    const wethContract = new ethers.Contract(
      wethContractAddress?.contractAddress,
      USDCToken,
      signer
    );

    try {
      const usdcBalances = await usdcContract.balanceOf(adminWalletAddress);
      const usdcDecimals = 6;
      const tokenBalance = ethers.utils.formatUnits(usdcBalances, usdcDecimals); // Convert to a human-readable format
      setUsdcBalance(tokenBalance);
      const wethBalances = await wethContract.balanceOf(adminWalletAddress);
      const wethDecimal = 18;
      const wethTokenBalance = ethers.utils.formatUnits(wethBalances, wethDecimal);
      console.log(wethTokenBalance);
      const provider = new ethers.providers.JsonRpcProvider(ARBITRUM_MAINNET_JSON_RPC_URL);
      const camelotRouter = new ethers.Contract(
        CAMELOT_ROUTER_ADDRESS_ARBITRUM_MAINNET,
        newAbi,
        provider
      );

      camelotRouter.functions
        .getAmountsOut(ethers.utils.parseUnits(wethTokenBalance, 18).toString(), path)
        .then((amounts) => {
          const [wethIn, usdcOut] = amounts[0];
          console.log("Amount of USDCe from WETH: ", ethers.utils.formatUnits(usdcOut, 6));
          console.log(ethers.utils.formatUnits(wethIn, 18));
          setWethToUsdcAmount(ethers.utils.formatUnits(usdcOut, 6));
          setWethBalance(ethers.utils.formatUnits(usdcOut, 6));
        });
    } catch (error) {
      console.log(error);
    }
  };

  console.log(usdcToWethTransferAmount, "UWT");

  const amount = ethers.utils.parseUnits(wethBalance ? wethBalance : "0", 18);

  console.log(amount);

  const handleGetAmountOut = async () => {
    const amount = ethers.utils.parseUnits("0.000000001239000017", 18);
    const provider = new ethers.providers.JsonRpcProvider(ARBITRUM_MAINNET_JSON_RPC_URL);

    const camelotRouter = new ethers.Contract(
      CAMELOT_ROUTER_ADDRESS_ARBITRUM_MAINNET,
      newAbi,
      provider
    );

    camelotRouter.functions.getAmountsOut(amount, path).then((amounts) => {
      const [wethIn, usdcOut] = amounts[0];
      console.log("Amount of USDCe from WETH: ", ethers.utils.formatUnits(usdcOut, 6));
      setWethToUsdcAmount(ethers.utils.formatUnits(usdcOut, 6));
    });
  };

  const handleGetWETHAmountOut = async () => {
    const amount = ethers.utils.parseUnits("2", 6);
    const path = [USDCe_ARBITRUM_MAINNET, WETH_ARBITRUM_MAINNET];
    const provider = new ethers.providers.JsonRpcProvider(ARBITRUM_MAINNET_JSON_RPC_URL);

    const camelotRouter = new ethers.Contract(
      CAMELOT_ROUTER_ADDRESS_ARBITRUM_MAINNET,
      newAbi,
      provider
    );

    camelotRouter.functions.getAmountsOut(amount, path).then((amounts) => {
      const [wethIn, usdcOut] = amounts[0];
      console.log("Amount of USDCe from WETH: ", ethers.utils.formatUnits(usdcOut, 18));
      console.log(ethers.utils.formatUnits(wethIn, 6));
      // setWethToUsdcAmount(ethers.utils.formatUnits(usdcOut, 18));
    });
  };

  // const treasuryWallet = "0xE6eA374e0ac9f367c92212850eb2C07Bf78d6679";
  // const personalWallet = "0x12eedB832eD1bBfef4f55eaBC5166564d23B6143";

  const handlePersonalWithdrawal = async (profit, id) => {
    console.log(profit); //USDC

    if (profit / 2 < wethToUsdcAmount) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = await provider.getSigner();

      const wethContract = new ethers.Contract(
        wethContractAddress?.contractAddress,
        USDCToken,
        signer
      );

      const tenPercentage = profit / 2; //USDC
      const amount = ethers.utils.parseUnits(tenPercentage.toString(), 6);
      const path = [USDCe_ARBITRUM_MAINNET, WETH_ARBITRUM_MAINNET];
      const providerr = new ethers.providers.JsonRpcProvider(ARBITRUM_MAINNET_JSON_RPC_URL);

      const camelotRouter = new ethers.Contract(
        CAMELOT_ROUTER_ADDRESS_ARBITRUM_MAINNET,
        newAbi,
        providerr
      );

      camelotRouter.functions.getAmountsOut(amount, path).then((amounts) => {
        const [wethIn, usdcOut] = amounts[0];
        console.log("Amount of USDCe from WETH: ", ethers.utils.formatUnits(usdcOut, 18));
        console.log(ethers.utils.formatUnits(wethIn, 6));
        setUsdcToWethTransferAmount(ethers.utils.formatUnits(usdcOut, 18));
      });
      try {
        const transactionToPersonalWallet = await wethContract.transfer(
          personalWallet,
          ethers.utils.parseUnits(usdcToWethTransferAmount).toString(),
          {
            gasLimit: 300000,
          }
        );
        await transactionToPersonalWallet.wait();
        console.log("Transaction hash:", transactionToPersonalWallet);
        if (transactionToPersonalWallet.hash) {
          const dataOne = {
            walletAddress: personalWallet,
            token: usdcToWethTransferAmount,
            paymentMethod: "WETH",
            transactionType: "PersonalWalletWithdrawal",
            exchangedToken: tenPercentage,
            gameInstanceId: id,
            transactionHash: transactionToPersonalWallet.hash,
          };

          const createTransaction = await createBlockchainTransaction(dataOne);
          console.log(createTransaction);
          alert("Token withdrawal to personal wallet successfully!");
          setTimeout(() => {
            window.location.reload();
          }, 300);
          return createTransaction;
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log(1);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = await provider.getSigner();
      // const usdcContract = new ethers.Contract(
      //   usdcContractAddress?.contractAddress,
      //   USDCToken,
      //   signer
      // );
      const amount = profit / 2;
      try {
        // const transactionToPersonalWallet = await usdcContract.transfer(
        //   personalWallet,
        //   amount * 1e6,
        //   {
        //     gasLimit: 300000,
        //   }
        // );
        // await transactionToPersonalWallet.wait();
        const router = new ethers.Contract(uniswapRouterAddress, UniswapV2Router, signer);
        const usdcToken = new ethers.Contract(usdcContractAddress, USDCToken, signer);

        const amountIn = ethers.utils.parseEther(amount.toString());
        const amountOutMin = ethers.utils.parseEther("0");

        // Approve the router to spend USDC
        await usdcToken.approve(uniswapRouterAddress, amountIn);

        const path = [usdcContractAddress, wethContractAddress];

        const transactionToPersonalWallet = await router.swapExactTokensForTokens(
          amountIn,
          amountOutMin,
          path,
          personalWallet,
          Date.now() + 1000 * 60 * 10, // 10 minutes
          { gasLimit: 1000000 } // Adjust the gas limit as needed
        );

        await transactionToPersonalWallet.wait();
        console.log(transactionToPersonalWallet);
        if (transactionToPersonalWallet.hash) {
          const dataOne = {
            walletAddress: personalWallet,
            token: amount,
            paymentMethod: "USDC",
            transactionType: "PersonalWalletWithdrawal",
            gameInstanceId: id,
            exchangedToken: amount,
            transactionHash: transactionToPersonalWallet.hash,
          };
          const createTransaction = await createBlockchainTransaction(dataOne);
          console.log(createTransaction);
          alert("Token withdrawal to personal wallet successfully!");
          setTimeout(() => {
            window.location.reload();
          }, 300);
          return createTransaction;
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleTreasuryWithdrawal = async (profit, id) => {
    console.log(profit);

    if (profit / 2 < wethToUsdcAmount) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = await provider.getSigner();

      const wethContract = new ethers.Contract(
        wethContractAddress?.contractAddress,
        USDCToken,
        signer
      );

      const tenPercentage = profit / 2; //USDC
      const amount = ethers.utils.parseUnits(tenPercentage.toString(), 6);
      const path = [USDCe_ARBITRUM_MAINNET, WETH_ARBITRUM_MAINNET];
      const providerr = new ethers.providers.JsonRpcProvider(ARBITRUM_MAINNET_JSON_RPC_URL);

      const camelotRouter = new ethers.Contract(
        CAMELOT_ROUTER_ADDRESS_ARBITRUM_MAINNET,
        newAbi,
        providerr
      );

      camelotRouter.functions.getAmountsOut(amount, path).then((amounts) => {
        const [wethIn, usdcOut] = amounts[0];
        console.log("Amount of USDCe from WETH: ", ethers.utils.formatUnits(usdcOut, 18));
        console.log(ethers.utils.formatUnits(wethIn, 6));
        setUsdcToWethTransferAmount(ethers.utils.formatUnits(usdcOut, 18));
      });

      try {
        const transactionToPersonalWallet = await wethContract.transfer(
          treasuryWallet,
          ethers.utils.parseUnits(usdcToWethTransferAmount).toString(),
          {
            gasLimit: 300000,
          }
        );
        await transactionToPersonalWallet.wait();
        console.log("Transaction hash:", transactionToPersonalWallet);
        if (transactionToPersonalWallet.hash) {
          const dataOne = {
            walletAddress: treasuryWallet,
            token: usdcToWethTransferAmount,
            paymentMethod: "WETH",
            transactionType: "TreasuryWalletWithdrawal",
            exchangedToken: tenPercentage,
            gameInstanceId: id,
            transactionHash: transactionToPersonalWallet.hash,
          };

          const createTransaction = await createBlockchainTransaction(dataOne);
          console.log(createTransaction);
          alert("Token withdrawal to personal wallet successfully!");
          setTimeout(() => {
            window.location.reload();
          }, 300);
          return createTransaction;
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = await provider.getSigner();

      const usdcContract = new ethers.Contract(
        usdcContractAddress?.contractAddress,
        USDCToken,
        signer
      );

      const amount = profit / 2;
      try {
        const transactionToPersonalWallet = await usdcContract.transfer(
          treasuryWallet,
          amount * 1e6,
          {
            gasLimit: 300000,
          }
        );
        await transactionToPersonalWallet.wait();
        if (transactionToPersonalWallet.hash) {
          const dataOne = {
            walletAddress: treasuryWallet,
            token: amount,
            paymentMethod: "USDC",
            transactionType: "TreasuryWalletWithdrawal",
            gameInstanceId: id,
            exchangedToken: amount,
            transactionHash: transactionToPersonalWallet.hash,
          };

          const createTransaction = await createBlockchainTransaction(dataOne);
          console.log(createTransaction);
          alert("Token withdrawal to personal wallet successfully!");
          setTimeout(() => {
            window.location.reload();
          }, 300);
          return createTransaction;
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  // /////////////////////
  // const usdcAddress = "0x8FB1E3fC51F3b789dED7557E680551d93Ea9d892";
  // const wethAddress = "0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f";

  // const swapTokens = async () => {
  //   try {
  //     const provider = new ethers.providers.Web3Provider(window.ethereum);
  //     await provider.send("eth_requestAccounts", []);
  //     const signer = await provider.getSigner();
  //     const router = new ethers.Contract(uniswapRouterAddress, UniswapV2Router, signer);
  //     const usdcToken = new ethers.Contract(usdcAddress, USDCToken, signer);

  //     const amountIn = ethers.utils.parseEther("10");
  //     const amountOutMin = ethers.utils.parseEther("0");

  //     // Approve the router to spend USDC
  //     await usdcToken.approve(uniswapRouterAddress, amountIn);

  //     const path = [usdcAddress, wethAddress];

  //     const tx = await router.swapExactTokensForTokens(
  //       amountIn,
  //       amountOutMin,
  //       path,
  //       adminWalletAddress,
  //       Date.now() + 1000 * 60 * 10, // 10 minutes
  //       { gasLimit: 1000000 } // Adjust the gas limit as needed
  //     );

  //     await tx.wait();
  //     console.log(tx);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const usdcPersonalWallet = async (profit, id) => {
    try {
      const percentage = parseInt(personalWalPercentage) / 100;
      const adminPercentage = parseInt(adminProfitPercentage) / 100;

      profit = profit * (percentage / adminPercentage);

      if (!profit || profit <= 0) {
        return alert("The transaction was rejected due to insufficient profit");
      }

      await adminTransaction(
        usdcContractAddress?.contractAddress,
        profit,
        personalWallet,
        id,
        "PersonalWalletWithdrawal"
      );
    } catch (error) {
      if (error?.code === "ACTION_REJECTED") {
        alert("User rejected the transaction.");
      } else if (error?.code === "NETWORK_ERROR") {
        alert("Network error occurred. Please check your connection and try again.");
      } else if (error?.code === "INSUFFICIENT_FUNDS") {
        alert("Insufficient funds for this transaction.");
      } else if (error?.code === "INVALID_ADDRESS") {
        alert("The provided address is invalid.");
      } else if (error?.code === "GAS_LIMIT_EXCEEDED") {
        alert("Gas limit exceeded. Please adjust and try again.");
      } else {
        console.error("An unexpected error occurred:", error);
        alert("An unexpected error occurred. Please try again later.");
      }
    }
  };

  const usdcTreasuryWallet = async (profit, id) => {
    try {
      const percentage = parseInt(treasuryWalPercentage) / 100;
      const adminPercentage = parseInt(adminProfitPercentage) / 100;

      profit = profit * (percentage / adminPercentage);

      // console.log(profit);
      if (!profit || profit <= 0) {
        return alert("The transaction was rejected due to insufficient profit");
      }

      await adminTransaction(
        usdcContractAddress?.contractAddress,
        profit,
        treasuryWallet,
        id,
        "TreasuryWalletWithdrawal"
      );
    } catch (error) {
      if (error?.code === "ACTION_REJECTED") {
        alert("User rejected the transaction.");
      } else if (error?.code === "NETWORK_ERROR") {
        alert("Network error occurred. Please check your connection and try again.");
      } else if (error?.code === "INSUFFICIENT_FUNDS") {
        alert("Insufficient funds for this transaction.");
      } else if (error?.code === "INVALID_ADDRESS") {
        alert("The provided address is invalid.");
      } else if (error?.code === "GAS_LIMIT_EXCEEDED") {
        alert("Gas limit exceeded. Please adjust and try again.");
      } else {
        console.error("An unexpected error occurred:", error);
        alert("An unexpected error occurred. Please try again later.");
      }
    }
  };

  return (
    <>
      <DashboardLayout>
        {/* <DashboardNavbar /> */}
        {loading ? (
          <>Loading...</>
        ) : (
          <MDBox pt={6} pb={3}>
            {/* {wethToUsdcAmount ? ( */}
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                  {/* <Button onClick={() => getAdminWalletBalace()}>Check Balance</Button> */}
                  <Typography>
                    {`WETH IN USDC : ${wethBalance === undefined ? "" : wethBalance}`}{" "}
                  </Typography>
                  <span style={{ width: "20px" }}></span>
                  <Typography>{`USDC : ${
                    usdcBalance === undefined ? "" : usdcBalance
                  }`}</Typography>

                  {/* <Button onClick={handleGetAmountOut}>WETH TO USDC</Button> */}
                  {/* <Button onClick={handleGetWETHAmountOut}>USDC TO WETH</Button> */}
                  {/* <Button onClick={swapTokens}>Swap</Button> */}
                </div>

                <Card>
                  <div>
                    <TableContainer>
                      <Table>
                        <StyledTableHead>
                          <TableRow>
                            <StyledTableCell>Game name</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Entry Fee</StyledTableCell>
                            {/* <StyledTableCell>Min players</StyledTableCell>
                            <StyledTableCell>Max players</StyledTableCell> */}
                            <StyledTableCell>Game Category</StyledTableCell>
                            <StyledTableCell>20 % of Game</StyledTableCell>
                            <StyledTableCell>Add To Personal Wallet</StyledTableCell>
                            <StyledTableCell>Add To Treasury Wallet</StyledTableCell>
                            {/* Add more table headers as needed */}
                          </TableRow>
                        </StyledTableHead>
                        {loading ? (
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              minHeight: "70vh",
                              minWidth: "70vw",
                              color: "white",
                              margin: 0,
                            }}
                          >
                            Loading...
                          </p>
                        ) : (
                          <TableBody>
                            {data.map((game) => (
                              <StyledTableRow key={game?._id}>
                                <StyledTableCell>{game?.game?.gameName}</StyledTableCell>
                                <StyledTableCell>{game?.status}</StyledTableCell>
                                <StyledTableCell>{game?.entryFees}</StyledTableCell>
                                <StyledTableCell>{game?.game.gameCategory}</StyledTableCell>
                                {/* <StyledTableCell>{game?.game?.minPlayer}</StyledTableCell>
                              <StyledTableCell>{game?.game?.maxPlayer}</StyledTableCell> */}
                                <StyledTableCell>
                                  {(game?.entryFees ?? 0) *
                                    (game?.game?.minPlayer ?? 0) *
                                    ((parseInt(adminProfitPercentage) ?? 0) / 100)}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {game?.personalWallet?.transactionStatus === "completed" ? (
                                    ""
                                  ) : game?.personalWallet?.transactionStatus === "pending" ? (
                                    <MDBox ml={-1}>
                                      <MDBadge
                                        badgeContent={game?.personalWallet?.transactionStatus}
                                        color={
                                          game?.personalWallet?.transactionStatus === "completed"
                                            ? "success"
                                            : "info"
                                        }
                                        variant="gradient"
                                        size="sm"
                                      />
                                    </MDBox>
                                  ) : (
                                    <Button
                                      onClick={() => {
                                        // handlePersonalWithdrawal(
                                        //   game?.entryFees * game?.game?.minPlayer * 0.2,
                                        //   game?._id
                                        // )

                                        const percentage = parseInt(adminProfitPercentage) / 100;
                                        usdcPersonalWallet(
                                          (game?.entryFees ?? 0) *
                                            (game?.game?.minPlayer ?? 0) *
                                            (percentage ?? 0),
                                          game?._id
                                        );
                                      }}
                                      variant="contained"
                                      sx={{ color: "white" }}
                                    >
                                      Withdraw
                                    </Button>
                                  )}

                                  {game?.personalWallet?.transactionStatus === "completed" && (
                                    <MDBox ml={-1}>
                                      <MDBadge
                                        badgeContent={game?.personalWallet?.transactionStatus}
                                        color={
                                          game?.personalWallet?.transactionStatus === "completed"
                                            ? "success"
                                            : "info"
                                        }
                                        variant="gradient"
                                        size="sm"
                                      />
                                    </MDBox>
                                  )}
                                </StyledTableCell>

                                <StyledTableCell>
                                  {game?.treasuryWallet?.transactionStatus === "completed" ? (
                                    ""
                                  ) : game?.treasuryWallet?.transactionStatus === "pending" ? (
                                    <MDBox ml={-1}>
                                      <MDBadge
                                        badgeContent={game?.treasuryWallet?.transactionStatus}
                                        color={
                                          game?.treasuryWallet?.transactionStatus === "completed"
                                            ? "success"
                                            : "info"
                                        }
                                        variant="gradient"
                                        size="sm"
                                      />
                                    </MDBox>
                                  ) : (
                                    <Button
                                      onClick={() => {
                                        // handleTreasuryWithdrawal(
                                        //   game?.entryFees * game?.game?.minPlayer * 0.2,
                                        //   game?._id
                                        // )
                                        const percentage = parseInt(adminProfitPercentage) / 100;
                                        usdcTreasuryWallet(
                                          (game?.entryFees ?? 0) *
                                            (game?.game?.minPlayer ?? 0) *
                                            (percentage ?? 0),
                                          game?._id
                                        );
                                      }}
                                      variant="contained"
                                      sx={{
                                        color: "white",
                                        background: "red",
                                        "&:hover": {
                                          backgroundColor: "red",
                                        },
                                        "&:focus": {
                                          backgroundColor: " #10ADEF95",
                                        },
                                      }}
                                    >
                                      Withdraw
                                    </Button>
                                  )}

                                  {game?.treasuryWallet?.transactionStatus === "completed" && (
                                    <MDBox ml={-1}>
                                      <MDBadge
                                        badgeContent={game?.treasuryWallet?.transactionStatus}
                                        color={
                                          game?.treasuryWallet?.transactionStatus === "completed"
                                            ? "success"
                                            : "info"
                                        }
                                        variant="gradient"
                                        size="sm"
                                      />
                                    </MDBox>
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </div>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={count}
                  style={{ color: "white" }}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                />
              </Grid>
            </Grid>
            {/* ) */}
            {/* : (
            <div style={{ marginLeft: "50%", marginBottom: "10%" }}>
              <Button big onClick={() => getAdminWalletBalace()}>
                Initiate Wallet
              </Button>
            </div>
            )} */}
          </MDBox>
        )}
      </DashboardLayout>
    </>
  );
};

export default AdminWithdrawal;
