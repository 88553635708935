import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Icon,
} from "@mui/material";
import { styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// import zipcelx from "zipcelx";
import MDTypography from "components/MDTypography";
import { createTree } from "services/api/gameAPI";

//web3
import TT4LToken from "../../Contract/TT4L.json";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";
// import { getTransaction } from "services/api/gameAPI";
import { updateMultipleIds } from "services/api/gameAPI";
import { getBlockChainWithdrawalTransactions } from "services/api/gameAPI";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
}));

// export const T4Address = "0xF083b8d6741c2c12c13864E94Ad05F697f315BAB";
export const T4Address = "0xcce4156709Df97ED66CFEcDA5802D8591cDC6160";

function Users() {
  let navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [term, setTerm] = useState("");
  const [stage, setStage] = useState(0);
  const [idValues, setIdValues] = useState([]);

  //

  // const [transferInfo, setTransferInfo] = useState({
  //   recipient: null,
  //   amount: null,
  // });
  // const [txProcessing, toggleTxProcessing] = useState(false);
  const [contractInfo, setContractInfo] = useState({
    address: "-",
    tokenName: "-",
    tokenSymbol: "-",
    totalSupply: "-",
  });

  // const [balanceInfo, setBalanceInfo] = useState({
  //   address: "-",
  //   balance: "-",
  // });
  useEffect(() => {
    const fetchData = async () => {
      // try {
      //   const res = await getTransaction({ page: page + 1, limit });
      //   console.log(res, "RES");
      //   setUsers(res.data?.data?.transaction.filter((x) => x.verified === false));
      //   const extractedIds = res.data?.data?.transaction.map((item) => item._id);
      //   setIdValues(extractedIds);
      //   setCount(res.data?.data?.transaction?.filter((x) => x.verified === false).length);
      //   setLoading(false);
      // } catch (error) {
      //   console.error(error);

      try {
        const res = await getBlockChainWithdrawalTransactions({ page: page + 1, limit });
        setUsers(res.data?.data?.data);
        const extractedIds = res.data?.data?.data?.map((item) => item._id);
        setIdValues(extractedIds);
        setCount(res.data?.data?.total);
        console.log(res.data, "RES");
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [page, limit, term]);

  console.log(users, "User");

  const noOfPages = Math.ceil(count / limit);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const erc20 = new ethers.Contract(T4Address, TT4LToken, provider);
    try {
      const tokenName = await erc20.name();
      const tokenSymbol = await erc20.symbol();
      const totalSupply = await erc20.totalSupply();
      await setContractInfo({
        address: T4Address,
        tokenName,
        tokenSymbol,
        totalSupply,
      });
      await console.log(contractInfo);
      await setStage(stage + 1);
      await setLoading(false);
    } catch (err) {
      alert("No wallet found");
      console.log(err);
    }
  };
  console.log(contractInfo, "CON IFO");
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };
  const handletitle = (e) => {
    setTerm(e.target.value);
    setPage(0);
  };
  // const columns = [
  //   { label: "username", value: (player) => player.username || "" },
  //   { label: "email", value: (player) => player.email || "" },
  //   { label: "Phone", value: (player) => player.phone || "" },
  //   { label: "gender", value: (player) => player?.gender || "" },
  //   { label: "name", value: (player) => player?.name || "" },
  // ];
  // const downloadExcel = (d) => {
  //   console.log("d", d);
  //   const headerRow = columns?.map((column) => ({ value: column.label, type: "string" }));
  //   const dataRows = d.users?.map((player, search) =>
  //     columns?.map((column) => ({
  //       value: column.value(player),
  //       type: "string",
  //     }))
  //   );
  //   const data = [headerRow].concat(dataRows);
  //   zipcelx({ filename: `420 wallet transaction`, sheet: { data } });
  // };
  const totalData = async () => {
    // const data = await getUsers({ page: page + 1, limit: count, term });
    // console.log(data, "data");
    // await downloadExcel(data.data.data);
    const res = await createTree(
      users.map((item) => ({
        walletAddress: item.walletAddress.toLowerCase(), // Convert to lowercase if needed
        token: parseInt(item.token).toString(), // Adjust the conversion factor as needed
        userId: item.userId,
      }))
    );
    if (res.status === 201) {
      console.log(res.data, "rootHash");

      await handleTransfer(res.data.rootHash);
    }
  };
  const handleTransfer = async (e) => {
    setStage(3);

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();
    const erc20 = new ethers.Contract(contractInfo.address, TT4LToken, signer);
    try {
      const transaction = await erc20.setRoot(`0x${e}`);
      await transaction.wait(); // Wait for the transaction to be mined

      // Transaction successful
      console.log("Transaction hash:", transaction.hash);
      if (transaction.hash) {
        alert("Success!");
        await updateMultipleIds(
          idValues.map((id) => ({
            id: id,
            transactionHash: transaction.hash,
          }))
        );
        navigate("/Merkle");
      }
    } catch (e) {
      alert(e.error.data.message);
      // window.location.reload(false);
    }
  };

  console.log({ ids: idValues });
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {loading ? (
        <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>Loading...</div>
      ) : (
        <>
          <Grid container spacing={6}>
            {users.length === 0 ? (
              <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>
                There are no records at the moment.
              </div>
            ) : (
              <>
                <Grid item xs={12}>
                  <Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "12px -1px",
                      }}
                    >
                      <MDBox margin="20px" pr={1}>
                        <MDInput
                          label="Search..."
                          value={term}
                          size="small"
                          onChange={handletitle}
                        />
                      </MDBox>
                      <MDTypography
                        component="label"
                        style={{ marginLeft: "0%", color: "white", marginTop: "10px" }}
                      >
                        Withdrawal Transaction
                      </MDTypography>
                      <MDTypography component="label" style={{ padding: "80px" }}>
                        {/* Add label text here */}
                      </MDTypography>
                      {/* {username === "admin" && ( */}
                      <div style={{ margin: "20px" }}>
                        {stage !== 0 ? (
                          <MDButton variant="contained" onClick={totalData} color="primary">
                            Create Root
                          </MDButton>
                        ) : (
                          <div></div>
                        )}
                      </div>
                      {/* )} */}
                    </div>

                    {stage === 0 ? (
                      <div style={{ marginLeft: "50%", marginBottom: "10%" }}>
                        <Button big onClick={handleSubmit}>
                          {stage === 0 ? "Initiate Wallet " : "Connected "}
                          <Icon>
                            {/* <img width={30} src="https://img.icons8.com/color/48/plus--v1.png" /> */}
                          </Icon>
                        </Button>
                      </div>
                    ) : stage === 1 ? (
                      <TableContainer>
                        <Table>
                          <StyledTableHead>
                            <TableRow>
                              <StyledTableCell>Wallet Address</StyledTableCell>
                              <StyledTableCell>Token</StyledTableCell>
                              <StyledTableCell>User ID</StyledTableCell>
                              <StyledTableCell>Created At</StyledTableCell>
                              {/* <StyledTableCell>View</StyledTableCell> */}
                              {/* Add more table headers as needed */}
                            </TableRow>
                          </StyledTableHead>
                          <TableBody>
                            {users?.map((user) => (
                              <StyledTableRow key={user._id}>
                                <StyledTableCell>{user.walletAddress}</StyledTableCell>
                                <StyledTableCell>
                                  {/* {Number(user.token).toFixed(2)} */}
                                  {(Number(user?.token) / 1e18).toFixed(2)}
                                </StyledTableCell>
                                <StyledTableCell>{user.userId}</StyledTableCell>
                                <StyledTableCell>
                                  {new Date(user?.createdAt).toLocaleString("en-US", {
                                    timeZone: "America/New_York",
                                  })}
                                </StyledTableCell>

                                {/* <StyledTableCell>
                                  <Link to={`/profile/${user._id}`} state={{ List: user._id }}>
                                    <MDButton color="info">VIEW</MDButton>
                                  </Link>
                                </StyledTableCell> */}
                                {/* Add more table cells for other user properties */}
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <p
                        style={{
                          fontFamily: "sans-serif",
                          color: "white",
                          fontWeight: "bolder",
                          paddingLeft: "20%",
                        }}
                      >
                        Transaction Sent... Root sent to verification
                      </p>
                    )}
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={noOfPages}
                    style={{ color: "white" }}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Footer />
        </>
      )}
    </DashboardLayout>
  );
}
export default Users;
