import styled from "@emotion/styled";
import {
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { getAllFaqs } from "services/api/gameAPI";
import CreateModalBox from "./createModal";
import { Box } from "@mui/system";
import AlertConfirm from "components/AlertConfirm";
import { deleteFaq } from "services/api/gameAPI";
import EditModal from "./editModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
}));

const Faq = () => {
  const [faqs, setFaqs] = useState([]);
  const [faqId, setFaqId] = useState();
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    const getAllFaq = async () => {
      const response = await getAllFaqs();
      setFaqs(response?.data?.data?.data);
      return response?.data;
    };
    getAllFaq();
  }, []);

  console.log(faqs);

  const handleFaqDelete = async (_id) => {
    setAlertOpen(true);
    setFaqId(_id);
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <>
        <Grid container spacing={6} sx={{ marginTop: 3 }}>
          <Grid item xs={12}>
            <Card sx={{ padding: 2 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "12px -1px",
                }}
              >
                <MDTypography
                  component="label"
                  style={{
                    padding: "40px",
                    color: "grey",
                    fontWeight: 600,
                  }}
                >
                  FAQ' S
                </MDTypography>

                <div style={{ margin: "20px" }}>
                  <CreateModalBox />
                </div>
              </div>

              <TableContainer>
                <Table>
                  <StyledTableHead>
                    <TableRow>
                      <StyledTableCell>No</StyledTableCell>
                      <StyledTableCell>Heading</StyledTableCell>

                      <StyledTableCell>Summary</StyledTableCell>
                      <StyledTableCell></StyledTableCell>

                      {/* Add more table headers as needed */}
                    </TableRow>
                  </StyledTableHead>
                  {faqs.length === 0 ? (
                    <div
                      style={{
                        color: "#FFF",
                        paddingLeft: "35%",
                        paddingBottom: "10%",
                        marginTop: 30,
                      }}
                    >
                      There are no records at the moment.
                    </div>
                  ) : (
                    <>
                      <TableBody>
                        {faqs?.map((faq, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell>{index + 1}</StyledTableCell>
                            <StyledTableCell>{faq?.question}</StyledTableCell>
                            <StyledTableCell>{faq?.answer}</StyledTableCell>
                            <StyledTableCell>
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                gap={2}
                              >
                                <EditModal faq={faq} />

                                <IconButton
                                  aria-label="delete"
                                  color="error"
                                  sx={{ marginBottom: 2 }}
                                  onClick={() => handleFaqDelete(faq._id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </>
                  )}
                </Table>
              </TableContainer>
            </Card>
          </Grid>
          <Grid item xs={12}>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div" */}
            {/* // count={count} */}
            {/* rowsPerPage={limit} */}
            {/* page={page} */}
            {/* // onPageChange={handlePageChange} */}
            {/* // onRowsPerPageChange={handleLimitChange} */}
            {/* /> */}
          </Grid>
        </Grid>
        <Footer />
        <AlertConfirm
          open={alertOpen}
          title="Delete"
          context="Do you want to delete Faq?"
          onClose={() => setAlertOpen(!alertOpen)}
          onAccept={async () => {
            try {
              console.log(JSON.stringify(faqId));
              await deleteFaq(faqId);
              alert("Faq deleted successful");
              setAlertOpen(!alertOpen);
              setTimeout(() => {
                window.location.reload();
              }, 300);
            } catch (error) {
              console.log(error);
            }
          }}
        />
      </>
    </DashboardLayout>
  );
};

export default Faq;
