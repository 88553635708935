import React, { forwardRef, useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
// import MDBox from "components/MDBox";
import {
  Autocomplete,
  Card,
  debounce,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import TimePicker from "react-time-picker";
import MDTypography from "components/MDTypography";
import { editGame, getGameConfig, getCryptos } from "services/api/gameAPI";
import InfoIcon from "@mui/icons-material/Info";
import { calCustomUser } from "services/api/gameAPI";

const style = {
  position: "absolute",
  top: { xs: "25%", md: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, sm: 700, lg: 800 },
  // bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  height: { xs: 300, sm: 350, md: 380, lg: 500 },
  overflowY: "scroll",
};

function ViewEditGame({ game }) {
  // console.log(game, "game");

  // console.log(typeof game.gameFrequency);

  const [open, setOpen] = React.useState(false);
  const handleOpen = async () => {
    try {
      const cryptosData = await getCryptos();

      const gameConfigData = await getGameConfig();
      console.log(cryptosData, gameConfigData);

      setCryptos(cryptosData.data.data);

      setGameConfig(gameConfigData.data.data);
      setOpen(true);
    } catch (error) {
      // Handle errors here

      console.error("Error fetching data:", error);
    }
  };
  const handleClose = () => setOpen(false);
  const [gameConfig, setGameConfig] = useState([]);
  const [cryptos, setCryptos] = useState([]);
  const [startTimeError, setStartTimeError] = useState(null);
  const [error, setError] = useState({ cryptos: "", positions: "" });
  // const [gameCategory, setGameCategory] = useState(
  //   game.gameCategory === "Multiplayer" ? true : false
  // );

  //
  const [minPlayer, setMinPlayer] = useState(game?.minPlayer);
  const [maxPlayer, setMaxPlayer] = useState(game?.maxPlayer);
  const [entryFees, setEntryFees] = useState(game?.entryFees);
  const [prizeMoney, setPrizeMoney] = useState(0);

  const [contestInterval, setContestInterval] = useState(game?.contestInterval ?? 0);
  const [gameDuration, setGameDuration] = useState(game?.gameDuration ?? 0);
  const [contestIntervalError, setContestIntervalError] = useState(null);

  console.log(prizeMoney);
  console.log(cryptos);

  const initialPrizemoney = game?.entryFees * game?.maxPlayer * 0.2;
  console.log(initialPrizemoney, "CAL");
  // const [h2hPrizeMoney, setH2hprizeMoney] = useState(0);

  const [userLimit, setUserLimit] = useState(game?.fup ?? 0);
  const [fupApiError, setFupApiError] = useState(null);

  // Debounce the API call to avoid making API calls on every keystroke
  const debouncedCheckUserAvailability = useCallback(
    debounce((per, minPlayer) => {
      handleUserAvailability(per, minPlayer);
    }, 300),
    [] // The empty array ensures the debounce function is created only once
  );

  const handleUserAvailability = async (per, minPlayer) => {
    try {
      setFupApiError(null);

      if (Number(per) <= 0) return;
      if (!per) return false;

      const response = await calCustomUser({
        enteredPercentage: parseInt(per),
        minPlayer: minPlayer,
      });
      const data = response.data;
      console.log(data);
      if (data?.data?.available == false) {
        setFupApiError("Not have enough fake users to add");
        return false;
      } else {
        setFupApiError(null);
        return true;
      }
    } catch (error) {
      console.error("API call failed:", error);
      setFupApiError("Failed to check fake user availability");
      return false;
    }
  };

  // add new input fields into the positions box
  // const HandleAddPositions = () => {
  //   let field = counter;

  //   const value = ""; // Example value calculation

  //   setData((prevData) => [
  //     ...prevData,
  //     {
  //       position: field,
  //       prize: value,
  //     },
  //     // [field]: value,
  //   ]);
  //   // eslint-disable-next-line no-plusplus
  //   setCounter((prevCounter) => prevCounter + 1);
  // };

  // // update the prize value in the data state
  // const handlepositionValues = (index, event) => {
  //   const updatedValue = event.target.value.trim() === "" ? 0 : Number(event.target.value);

  //   setData((prevData) => {
  //     const newData = [...prevData];
  //     newData[index] = {
  //       ...newData[index],
  //       prize: updatedValue,
  //     };
  //     return newData;
  //   });
  // };

  // const handleDelete = (key) => {
  //   const updatedData = data.filter((_, index) => index !== key);
  //   setData(updatedData);
  //   setCounter((prevCounter) => prevCounter - 1);
  // };

  // const getSuffix = (pos) => {
  //   if (pos === 1) {
  //     return "st";
  //   }
  //   if (pos === 2) {
  //     return "nd";
  //   }
  //   if (pos === 3) {
  //     return "rd";
  //   }
  //   return "th";
  // };
  // const getPositionLabel = (position) => {
  //   const suffix = getSuffix(position);
  //   return position + suffix;
  // };

  // const renderData = data?.map((dat, key) => (
  //   <div
  //     style={{
  //       color: "white",
  //       display: "flex",
  //       marginTop: "10px",
  //       gap: "20px",
  //       paddingLeft: "20px",
  //     }}
  //     key={dat.position}
  //   >
  //     <h5>{getPositionLabel(dat.position)} place:</h5>
  //     {/* <p>Value: {data[key]}</p> */}
  //     <input
  //       style={
  //         dat.position === 2
  //           ? { marginLeft: "-4px", height: "30px", padding: "10px" }
  //           : dat.position >= 10
  //           ? { marginLeft: "-9px", height: "30px", padding: "10px" }
  //           : { height: "30px", padding: "10px" }
  //       }
  //       type="number"
  //       value={dat.prize}
  //       onChange={(event) => handlepositionValues(key, event)}
  //     />{" "}
  //     <h5>CHAMPS</h5>
  //     {data.length === key + 1 ? <Button onClick={() => handleDelete(key)}>delete</Button> : ""}
  //   </div>
  // ));
  const { handleSubmit, control, setValue } = useForm({
    // resolver: yupResolver(ProductSchema),
  });

  const CreateGame = async (gameData) => {
    // const isAnyPrizeNull = data.some((item) => item.prize === undefined || item.prize === "");

    if (gameData.cryptos.length < gameData.gameType) {
      setError({
        ...error,
        cryptos: `Please select atleast ${gameData.gameType} cryptos to continue `,
      });
      return;
    }

    // if (isAnyPrizeNull) {
    //   setError({ ...error, positions: "Please fill all the prize values" });
    //   console.log("One or more prize values are null or empty. Cannot create game.");
    //   return; // Do not proceed further
    // }
    // if (isAnyPrizeNull) {
    //   console.log("One or more prize values are null or empty. Cannot create game.");
    //   return; // Do not proceed further
    // }

    if (fupApiError) {
      return alert(fupApiError);
    }

    let frequent;
    if (gameData?.gameGenerate === "One Time") {
      frequent = false;
    }

    try {
      // if (gameCategory) {
      if (Number(gameData?.entryFees) <= 0)
        return alert("Please select entry fees greater than zero!");
      const body = {
        ...gameData,
        // positions: data,
        fup: parseInt(gameData?.fup),
        entryFees: Number(gameData.entryFees),
        isFrequently: frequent,
        maxPlayer: maxPlayer,
        minPlayer: minPlayer,
        gamePaymentType: "Paid",
        // prizeMoney: prizeMoney,
      };
      const gameEdit = await editGame(game._id, body);
      if (gameEdit) {
        setOpen(false);
        alert("Success! Game changes have been saved.");
        setTimeout(() => {
          window.location.reload();
        }, 350);
      }
      // } else {
      //   const body = {
      //     ...gameData,
      //     // positions: data,
      //     isFrequently: frequent,
      //     maxPlayer: maxPlayer,
      //     minPlayer: minPlayer,
      //   };

      //   const gameEdit = await editGame(game._id, body);
      //   if (gameEdit) {
      //     setOpen(false);
      //     alert("Succesfully edited the game");
      //     setTimeout(() => {
      //       window.location.reload();
      //     }, 350);
      //   }
      // }
    } catch (error) {
      console.log(error);
      alert(error.response.data?.error);
    }

    // const body = {
    //   ...gameData,
    //   positions: data,
    //   isFrequently: frequent,
    //   maxPlayer: maxPlayer,
    //   minPlayer: minPlayer,
    // };

    // console.log(body, "BODY");
    // // eslint-disable-next-line react/prop-types
    // try {
    //   const gameEdit = await editGame(game._id, body);
    //   if (gameEdit) {
    //     setOpen(false);
    //     alert("Succesfully edited the game");
    //     setTimeout(() => {
    //       window.location.reload();
    //     }, 350);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  useEffect(() => {
    const handleCalculatePrizeMoney = () => {
      const totalAmount = entryFees * minPlayer;
      const totalprize = entryFees * minPlayer * 0.2;
      setPrizeMoney(totalAmount - totalprize);
    };
    handleCalculatePrizeMoney();
  }, [entryFees, minPlayer]);

  console.log(prizeMoney, "P");
  // useEffect(() => {
  //   // const headToHeadPriceMoney = data?.positions?.reduce((sum, dat) => sum + dat.prize, 0);
  //   const result = data?.reduce(function (acc, obj) {
  //     return acc + obj?.prize;
  //   }, 0);
  //   console.log(result, "PrizeM");
  // }, [data]);

  return (
    <div>
      <Button onClick={handleOpen} disabled={game?.isEditable === false} variant="contained">
        View/Edit
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                  padding: 3,
                }}
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  p={1.5}
                  sx={{ textAlign: "center", marginTop: "-21px" }}
                >
                  Game Details
                </MDTypography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    padding: 3,
                    flexWrap: "wrap",
                    flexDirection: "column",
                    mt: -5,
                  }}
                  component="form"
                  onSubmit={handleSubmit(CreateGame)}
                >
                  {/* <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              /> */}
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    <Controller
                      name="gameName"
                      control={control}
                      defaultValue={game.gameName}
                      rules={{ required: "Game name is required" }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          id="outlined-basic"
                          label="Game Name"
                          value={value}
                          onChange={onChange}
                          variant="outlined"
                          error={!!error}
                          helperText={error ? error.message : null}
                          autoComplete="off"
                        />
                      )}
                    />
                    {/* 
                    <Controller
                      name="gameCategory"
                      control={control}
                      defaultValue={game.gameCategory}
                      rules={{ required: "Game category is required" }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          sx={{ width: 180 }}
                          // getOptionLabel={(option: any) => option.product_name}
                          options={gameConfig?.gameCategory}
                          getOptionLabel={(option) => option}
                          onChange={(event, newValue) => {
                            onChange(newValue || ""); // Use newValue instead of value
                            if (newValue === "Multiplayer") {
                              setGameCategory(true);
                            } else {
                              setGameCategory(false);
                              setMinPlayer(2);
                              setMaxPlayer(2);
                            }
                          }}
                          value={
                            value
                              ? gameConfig?.gameCategory?.find((option) => option === value)
                              : undefined
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="outlined-basic"
                              label="Game Category"
                              variant="outlined"
                              sx={{
                                "& .MuiInputBase-root": {
                                  height: 45,
                                },
                              }}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      )}
                    /> */}
                    <Controller
                      name="gameCategory"
                      control={control}
                      defaultValue={game?.gameCategory}
                      rules={{ required: "Game category is required" }}
                      render={({ field: { value }, fieldState: { error } }) => (
                        <TextField
                          id="outlined-basic"
                          label="Game category"
                          value={"Multiplayer"}
                          // onChange={onChange}
                          variant="outlined"
                          error={!!error}
                          // disabled
                          helperText={error ? error.message : null}
                          autoComplete="off"
                        />
                      )}
                    />

                    {/* {gameCategory ? ( */}
                    <Controller
                      name="payoutStructure"
                      control={control}
                      defaultValue={game?.payoutStructure}
                      rules={{ required: "Game generate is required" }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return (
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            sx={{ width: 180 }}
                            options={gameConfig?.payout}
                            getOptionLabel={(option) =>
                              (option?.name ? `${option?.name ?? ""} - ` : "") +
                              " Top " +
                              option?.minPlayer
                            }
                            onChange={(event, newValue) => {
                              onChange(newValue?.id || "");
                              // Use newValue instead of value
                              setMinPlayer(parseInt(newValue?.minPlayer ?? 0));
                              setMaxPlayer((newValue?.minPlayer ?? 0) * 2);
                              setFupApiError(null);
                              debouncedCheckUserAvailability(
                                userLimit,
                                parseInt(newValue?.minPlayer ?? 0)
                              );
                            }}
                            value={
                              value
                                ? gameConfig?.payout?.find((option) => value === option?.id)
                                : null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="outlined-basic"
                                label="Payout Structure"
                                variant="outlined"
                                sx={{
                                  "& .MuiInputBase-root": {
                                    height: 45,
                                  },
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        );
                      }}
                    />
                    {/* ) : (
                      ""
                    )} */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Maximum Players"
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                      value={maxPlayer}
                      // onChange={(e) => onChange(Number(e.target.value) || "")}
                      // error={!!error}
                      // helperText={error ? error.message : null}
                      // autoComplete="off"
                    />
                    {/* )}
                        /> */}
                    {/* <Controller
                          name="minPlayer"
                          control={control}
                          defaultValue=""
                          // rules={{ required: "Minimum players is required" }}
                          render={({ field: { onChange }, fieldState: { error } }) => ( */}
                    <TextField
                      id="outlined-basic"
                      name="minPlayer"
                      label="Minimum Players"
                      type="number"
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                      value={minPlayer}
                      // onChange={(e) => onChange(e.target.value)}
                      // error={!!error}
                      // helperText={error ? error.message : null}
                      // autoComplete="off"
                    />
                    {/* <Controller
                      name="minPlayer"
                      control={control}
                      defaultValue={game.minPlayer}
                      rules={{ required: "Minimum players is required" }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          id="outlined-basic"
                          label="Minimum Players"
                          variant="outlined"
                          value={value}
                          onChange={(e) => onChange(Number(e.target.value) || "")}
                          error={!!error}
                          helperText={error ? error.message : null}
                          autoComplete="off"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="maxPlayer"
                      control={control}
                      defaultValue={game.maxPlayer}
                      rules={{ required: "Maximum players is required" }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          id="outlined-basic"
                          label="Maximum Players"
                          variant="outlined"
                          value={value}
                          onChange={(e) => onChange(Number(e.target.value) || "")}
                          error={!!error}
                          helperText={error ? error.message : null}
                          autoComplete="off"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      )}
                    /> */}

                    {/* <Controller
                      name="entryFees"
                      control={control}
                      defaultValue={game.entryFees}
                      rules={{ required: "Entry fees is required" }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          id="outlined-basic"
                          label="Entry Fees"
                          variant="outlined"
                          value={value}
                          onChange={(e) => {
                            onChange(Number(e.target.value) || "");
                            setEntryFees(e?.target.value || "");
                          }}
                          error={!!error}
                          helperText={error ? error.message : null}
                          autoComplete="off"
                        />
                      )}
                    /> */}
                    <Controller
                      name="entryFees"
                      control={control}
                      defaultValue={game.entryFees}
                      rules={{ required: "Entry fees is required" }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          id="outlined-basic"
                          label="Entry Fees"
                          variant="outlined"
                          value={value}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Allow empty input or valid numbers including decimals
                            if (/^\d*\.?\d*$/.test(inputValue)) {
                              onChange(inputValue ?? 0);
                              setEntryFees(inputValue ?? 0);
                            }
                          }}
                          error={!!error}
                          helperText={error ? error.message : null}
                          autoComplete="off"
                        />
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "12px",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {/* {gameCategory ? ( */}
                    <TextField
                      // name="prizeMoney"
                      id="outlined-basic"
                      label="Prize Money"
                      // type="number"
                      variant="outlined"
                      // defaultValue={game?.entryFees}
                      value={prizeMoney}
                      // onChange={(e) => onChange(Number(e.target.value) || "")}
                      // error={!!error}
                      // helperText={error ? error.message : null}
                      // autoComplete="off"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    {/* ) : ( */}
                    {/* <Controller
                        name="prizeMoney"
                        control={control}
                        defaultValue={h2hPrizeMoney}
                        rules={{ required: "Prize money is required" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            id="outlined-basic"
                            label="Prize Money"
                            variant="outlined"
                            value={h2hPrizeMoney}
                            onChange={(e) => onChange(Number(e.target.value) || "")}
                            error={!!error}
                            helperText={error ? error.message : null}
                            autoComplete="off"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        )}
                      /> */}
                    {/* )} */}
                    {/* <Controller
                      name="prizeMoney"
                      control={control}
                      defaultValue={game.prizeMoney}
                      rules={{ required: "Prize money is required" }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          id="outlined-basic"
                          label="Prize Money"
                          variant="outlined"
                          value={value}
                          onChange={(e) => onChange(Number(e.target.value) || "")}
                          error={!!error}
                          helperText={error ? error.message : null}
                          autoComplete="off"
                        />
                      )}
                    /> */}

                    <Controller
                      name="gameFrequency"
                      control={control}
                      defaultValue={game?.gameFrequency}
                      rules={{ required: "Game frequency is required" }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return (
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            sx={{ width: 180 }}
                            options={gameConfig?.gameFrequency}
                            getOptionLabel={(option) => option}
                            onChange={(event, newValue) => {
                              onChange(newValue || ""); // Use newValue instead of value
                            }}
                            value={
                              value
                                ? gameConfig?.gameFrequency?.find((option) => option === value)
                                : null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="outlined-basic"
                                label="Game Frequency"
                                variant="outlined"
                                sx={{
                                  "& .MuiInputBase-root": {
                                    height: 45,
                                  },
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        );
                      }}
                    />

                    <Controller
                      name="gameDuration"
                      control={control}
                      defaultValue={game?.gameDuration}
                      rules={{ required: "Entry fees is required" }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          id="outlined-basic"
                          label="Game Duration"
                          variant="outlined"
                          value={value}
                          // onChange={(e) => onChange(Number(e.target.value) || "")}
                          onChange={(e) => {
                            const interval = Number(e.target.value); //this will take only number
                            onChange(interval || "");
                            setGameDuration(interval);

                            if ((contestInterval ?? 0) < interval) {
                              setContestInterval(interval);
                              setValue("contestInterval", interval);
                              setContestIntervalError(null);
                            }
                          }}
                          error={!!error}
                          helperText={error ? error.message : null}
                          autoComplete="off"
                        />
                      )}
                    />

                    {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "18px",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    <Controller
                      name="gameType"
                      control={control}
                      defaultValue={game.gameType}
                      rules={{ required: "Game type is required" }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          sx={{ width: 180 }}
                          options={gameConfig?.gameType}
                          getOptionLabel={(option) => `pick ${option}`}
                          onChange={(event, newValue) => {
                            onChange(newValue || ""); // Use newValue instead of value
                          }}
                          value={
                            value
                              ? gameConfig?.gameType?.find((option) => option === value)
                              : undefined
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="outlined-basic"
                              label="Game Type"
                              variant="outlined"
                              sx={{
                                "& .MuiInputBase-root": {
                                  height: 45,
                                },
                              }}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name="winnerType"
                      control={control}
                      defaultValue={game.winnerType}
                      rules={{ required: "Winner type is required" }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          sx={{ width: 180 }}
                          // getOptionLabel={(option: any) => option.product_name}
                          options={gameConfig?.winnerType}
                          getOptionLabel={(option) => option}
                          onChange={(event, newValue) => {
                            onChange(newValue || ""); // Use newValue instead of value
                          }}
                          value={
                            value
                              ? gameConfig?.winnerType?.find((option) => option === value)
                              : undefined
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="outlined-basic"
                              label="Winner Type"
                              variant="outlined"
                              sx={{
                                "& .MuiInputBase-root": {
                                  height: 45,
                                },
                              }}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      )}
                    />
                    <Grid container display={"flex"} alignItems={"center"} flexDirection={"column"}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "18px",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Box>
                          <InputLabel sx={{ marginBottom: -1 }}>
                            <Tooltip
                              title={
                                "Automatically fills with fake users if actual user participation is below the specified threshold percentage."
                              }
                              placement="top"
                            >
                              <InfoIcon sx={{ fontSize: 20, cursor: "pointer" }} />
                            </Tooltip>
                            {"    User Threshold Auto-Fill"}

                            {userLimit == 0 && (
                              <>
                                <br />
                                <Typography fontSize={12} sx={{ ml: 2 }}>
                                  {"   Disable Bot Users"}
                                </Typography>
                              </>
                            )}

                            {userLimit >= 100 && (
                              <>
                                <br />
                                <Typography fontSize={12} sx={{ ml: 2 }}>
                                  {"Start the game using bot users"}
                                </Typography>
                              </>
                            )}
                          </InputLabel>
                          <Controller
                            name="fup"
                            control={control}
                            defaultValue={game?.fup ?? 0}
                            rules={{
                              required: "User Threshold Auto-Fill percentage is required",
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              // <Box
                              //   sx={{
                              //     display: "flex",
                              //     direction: "flex-column",
                              //   }}
                              // >
                              <FormControl>
                                {/* <InputLabel sx={{}}>
                                    <InfoIcon />
                                    User Threshold Auto-Fill
                                  </InputLabel> */}

                                <TextField
                                  sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                      {
                                        display: "none",
                                      },
                                    "& input[type=number]": {
                                      MozAppearance: "textfield",
                                    },
                                    marginTop: 2,
                                    width: 170,
                                  }}
                                  id="outlined-basic"
                                  // label="User Threshold Auto-Fill"
                                  variant="outlined"
                                  value={value}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Typography sx={{ color: "#FFFFFF" }}>%</Typography>
                                      </InputAdornment>
                                    ),
                                    // startAdornment: (
                                    //   <InputAdornment position="end">
                                    //     <InfoIcon />
                                    //   </InputAdornment>
                                    // ),
                                    inputProps: {
                                      max: 100,
                                      min: userLimit == 0 ? 0 : 50,
                                    },
                                  }}
                                  type="number"
                                  defaultValue={0}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    // Allow empty input or valid numbers including decimals
                                    if (/^\d*\.?\d*$/.test(inputValue)) {
                                      onChange(inputValue);
                                      setUserLimit(inputValue);
                                      console.log(parseInt(e?.target?.value ?? 0));

                                      if (parseInt(e?.target?.value ?? 0) >= 50) {
                                        debouncedCheckUserAvailability(inputValue, minPlayer);
                                      } else {
                                        if (parseInt(e?.target?.value ?? 0) != 0) {
                                          setFupApiError(
                                            "Value must be greater than or equal to 50"
                                          );
                                        } else {
                                          setFupApiError(null);
                                        }
                                      }
                                    }
                                  }}
                                  error={!!error || !!fupApiError}
                                  helperText={error ? error?.message : fupApiError ?? null}
                                  autoComplete="off"
                                />
                              </FormControl>
                            )}
                          />
                        </Box>

                        <Box
                          sx={{
                            marginTop: userLimit == 0 || userLimit >= 100 ? 3.8 : 2,
                          }}
                        >
                          <Controller
                            name="startTime"
                            control={control}
                            rules={{ required: "Start time is required" }}
                            defaultValue={game.startTime}
                            render={({ field }) => (
                              <FormControl>
                                <InputLabel sx={{ mt: -3 }}>Start Time</InputLabel>
                                <TimePicker
                                  onChange={(value) => {
                                    field.onChange(value);
                                    setStartTimeError(null); // Reset the error state on value change
                                  }}
                                  value={field.value}
                                  disableClock
                                  required
                                  clearIcon={false}
                                  format="h:m a"
                                  hourPlaceholder="-"
                                  className={`custom-timepicker ${startTimeError ? "error" : ""}`}
                                />
                                {startTimeError && <p className="error-text">{startTimeError}</p>}
                              </FormControl>
                            )}
                          />
                        </Box>

                        <Box
                          sx={{
                            marginTop: userLimit == 0 || userLimit >= 100 ? 5 : 3,
                          }}
                        >
                          <Controller
                            name="contestInterval"
                            control={control}
                            defaultValue={game?.contestInterval}
                            rules={{ required: "Contest interval is required" }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                id="outlined-basic"
                                label="Contest Interval"
                                variant="outlined"
                                value={value || contestInterval || ""}
                                type="number"
                                onChange={(e) => {
                                  const interval = Number(e.target.value); //this will take only number
                                  onChange(interval || "");
                                  setContestInterval(interval);

                                  if (interval < gameDuration) {
                                    setContestIntervalError(
                                      "value must be greater than or equal to gameDuration"
                                    );
                                  } else {
                                    setContestIntervalError(null);
                                  }
                                }}
                                InputProps={{
                                  inputProps: {
                                    min: gameDuration ?? 0,
                                  },
                                }}
                                error={!!error || !!contestIntervalError}
                                helperText={error ? error.message : contestIntervalError ?? null}
                                autoComplete="off"
                                sx={{
                                  "& .MuiFormHelperText-root": {
                                    marginLeft: "0px", // Adjust margin to prevent wrapping
                                    marginRight: "0px",
                                  },
                                  width: "11.5rem",
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                      {/* {gameCategory ? ( */}
                      <Controller
                        name="gameGenerate"
                        control={control}
                        defaultValue={game?.gameGenerate}
                        rules={{ required: "Game generate is required" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => {
                          return (
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              sx={{ width: 180, marginTop: "15px" }}
                              options={gameConfig?.gameGenerate}
                              getOptionLabel={(option) => option}
                              onChange={(event, newValue) => {
                                onChange(newValue || ""); // Use newValue instead of value
                              }}
                              value={
                                value
                                  ? gameConfig?.gameGenerate?.find((option) => option === value)
                                  : null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="outlined-basic"
                                  label="Game Generate"
                                  variant="outlined"
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      height: 45,
                                    },
                                  }}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          );
                        }}
                      />
                      {/* ) : (
                        ""
                      )} */}
                    </Grid>

                    {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "100%",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <Typography variant="body2" sx={{ color: "red" }}>
                      {error.cryptos ? error.cryptos : ""}
                    </Typography>
                    <Controller
                      name="cryptos"
                      control={control}
                      defaultValue={game.cryptos.map((coin) => coin._id)}
                      rules={{ required: "Crypto values are required" }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Autocomplete
                          multiple
                          id="tags-standard"
                          sx={{ width: { xs: "240px", md: "575px" } }}
                          options={cryptos}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, newValue) => {
                            onChange(newValue ? newValue.map((option) => option._id) : []);
                          }}
                          value={
                            value ? cryptos.filter((option) => value.includes(option._id)) : []
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="outlined-basic"
                              label="Cryptos"
                              variant="outlined"
                              error={!!error}
                              helperText={error ? error.message : null}
                              // sx={{
                              //   "& .MuiInputBase-root": {
                              //     width: "100%", // Set the minimum width of the Autocomplete component
                              //     height: "auto", // Let the width adjust based on content and selected values
                              //   },
                              // }}
                            />
                          )}
                          // PaperProps={{
                          //   style: {
                          //     maxHeight: 200,
                          //     overflow: "scroll",
                          //   },
                          // }}
                        />
                      )}
                    />

                    {/* {!gameCategory ? (
                      <MDBox>
                        {" "}
                        <Typography variant="body2" sx={{ color: "red" }}>
                          {error.positions ? error.positions : ""}
                        </Typography>
                        <Card
                          sx={{
                            width: { xs: "240px", md: "575px" },
                            height: "180px",
                            border: "1px solid white",

                            display: "flex",
                            padding: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <h5 style={{ color: "white" }}>Positions</h5>
                            <Button
                              onClick={HandleAddPositions}
                              variant="contained"
                              size="small"
                              sx={{ width: "40px", height: "20px", marginRight: "20px" }}
                            >
                              add
                            </Button>
                          </Box>
                         
                          <div style={{ overflowY: "scroll" }}>{renderData}</div>
                        </Card>
                      </MDBox>
                    ) : (
                      ""
                    )} */}

                    {/* <TextField
                  id="outlined-basic"
                  label="Positions"
                  variant="outlined"
                  sx={{
                    width: "550px",
                    "& .MuiInputBase-root": {
                      height: 80,
                    },
                  }}
                /> */}
                    {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
                    {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ textAlign: "center" }}
                      // disabled={!isValid}
                    >
                      Edit
                    </Button>
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

const ForwardedViewEditGame = forwardRef(ViewEditGame);

export default ForwardedViewEditGame;

ViewEditGame.propTypes = {
  game: PropTypes.shape({
    gameName: PropTypes.string.isRequired,
    maxPlayer: PropTypes.number.isRequired,
    minPlayer: PropTypes.number.isRequired,
    prizeMoney: PropTypes.number.isRequired,
    entryFees: PropTypes.number.isRequired,
    gameFrequency: PropTypes.string.isRequired,
    gameType: PropTypes.string.isRequired,
    gameCategory: PropTypes.string.isRequired,
    winnerType: PropTypes.string.isRequired,
    cryptos: PropTypes.arrayOf(PropTypes.string).isRequired,
    gameDuration: PropTypes.number.isRequired,
    // positions: PropTypes.arrayOf(PropTypes.object).isRequired,
    startTime: PropTypes.string.isRequired,
    payoutStructure: PropTypes.string.isRequired,

    // Define the structure and types of properties in the game object
  }).isRequired,
};
