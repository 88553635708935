import { CircularProgress } from "@mui/material";
import React from "react";

const ProgressLoading = () => {
  return (
    <>
      <div
        style={{
          color: "#FFF",
          width: "100%",
          marginTop: "20%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="info" />
      </div>
    </>
  );
};

export default ProgressLoading;
