import styled from "@emotion/styled";
import {
  Avatar,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import AlertConfirm from "components/AlertConfirm";
import BackButton from "components/BackButton/BackButton";
// import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import ProgressLoading from "components/ProgressLoading/ProgressLoading";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { removeInactiveCryptos } from "services/api/gameAPI";
import { getActiveAndInActieCryptos } from "services/api/gameAPI";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableHead = styled(TableHead)(() => ({
  display: "table-header-group",
}));

const InActiveCryptos = () => {
  const [cryptos, setCryptos] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [cryptoId, setCryptoId] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [action, setAction] = useState("");

  // const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getActiveAndInActieCryptos({
          page: page + 1,
          limit,
        });
        console.log(response.data, "response");
        setCryptos(response.data?.data?.data);
        setCount(response.data?.data?.totalCount);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [page, limit]);

  const submit = (id) => {
    setAlertOpen(true);
    setCryptoId(id);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  console.log(cryptos, "cryptos");
  return (
    <>
      {" "}
      <DashboardLayout>
        {/* <DashboardNavbar /> */}
        <BackButton />
        {loading ? (
          <ProgressLoading />
        ) : (
          <>
            <Grid container spacing={6}>
              <>
                <Grid item xs={12}>
                  <Card sx={{ paddingY: 2, marginTop: 2 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        padding: "12px 24px",
                      }}
                    >
                      {" "}
                      {/* <MDButton
                        variant="contained"
                        onClick={() => navigate("/shownew-updatedcrypto")}
                        color="info"
                      >
                        Show 24H Updated Cryptos
                      </MDButton> */}
                    </div>
                    <TableContainer>
                      <Table>
                        {cryptos?.length === 0 ? (
                          <Typography
                            sx={{
                              color: "#fff",
                              textAlign: "center",
                              paddingY: 10,
                            }}
                          >
                            There are no records at the moment.
                          </Typography>
                        ) : (
                          <>
                            <StyledTableHead>
                              <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Symbol</StyledTableCell>
                                <StyledTableCell> Price</StyledTableCell>
                                <StyledTableCell>Open 24h</StyledTableCell>
                                {/* <StyledTableCell>Price Change Percentage 24hr</StyledTableCell> */}
                                <StyledTableCell
                                  sx={{
                                    paddingLeft: 5,
                                  }}
                                >
                                  Status
                                </StyledTableCell>
                                <StyledTableCell>Action</StyledTableCell>
                              </TableRow>
                            </StyledTableHead>

                            <TableBody>
                              {cryptos?.map((crypto) => (
                                <StyledTableRow key={crypto?._id}>
                                  <StyledTableCell
                                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                                  >
                                    <Avatar src={crypto?.image} />
                                    {crypto?.name}
                                  </StyledTableCell>
                                  <StyledTableCell>{crypto?.symbol}</StyledTableCell>
                                  <StyledTableCell>${crypto?.price}</StyledTableCell>
                                  <StyledTableCell>${crypto?.open_24h}</StyledTableCell>
                                  <StyledTableCell>
                                    {" "}
                                    {/* <MDBadge
                                      badgeContent={
                                        crypto?.price === crypto?.open_24h ? "Inactive" : "Active"
                                      }
                                      color={
                                        crypto?.price === crypto?.open_24h ? "error" : "success"
                                      }
                                      variant="gradient"
                                      size="sm"
                                    /> */}
                                    <Tooltip
                                      title={`Crypto has been ${
                                        crypto?.price === crypto?.open_24h ? "Inactive" : "Active"
                                      } in the last 24 hours. You have the option to ${
                                        crypto?.price === crypto?.open_24h ? "disable" : "enable"
                                      } the crypto.`}
                                      slotProps={{
                                        popper: {
                                          sx: {
                                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                              {
                                                marginTop: "0px",
                                              },
                                            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                              {
                                                marginBottom: "0px",
                                              },
                                            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                                              {
                                                marginLeft: "0px",
                                              },
                                            [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                                              {
                                                marginRight: "0px",
                                              },
                                          },
                                        },
                                      }}
                                    >
                                      <MDButton
                                        variant="text"
                                        sx={{
                                          color:
                                            crypto?.price === crypto?.open_24h ? "red" : "green",
                                          "&:hover": {
                                            color: "white",
                                          },
                                        }}
                                      >
                                        {crypto?.price === crypto?.open_24h ? "Inactive" : "Active"}
                                      </MDButton>
                                    </Tooltip>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {crypto.isDisabled ? (
                                      <MDButton
                                        color="success"
                                        onClick={() => {
                                          submit(crypto._id);
                                          setAction("enable");
                                        }}
                                      >
                                        Enable
                                      </MDButton>
                                    ) : (
                                      <MDButton
                                        color="error"
                                        onClick={() => {
                                          submit(crypto._id);
                                          setAction("disable");
                                        }}
                                      >
                                        Disable
                                      </MDButton>
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </>
                        )}
                      </Table>
                    </TableContainer>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={count}
                    labelRowsPerPage="Rows per page:"
                    style={{ color: "white" }} // Change the color here
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                  />
                </Grid>
              </>
            </Grid>
          </>
        )}
        <AlertConfirm
          open={alertOpen}
          title="Create"
          context={`Do you want ${action} crypto?`}
          onClose={() => setAlertOpen(!alertOpen)}
          onAccept={async () => {
            try {
              const response = await removeInactiveCryptos({ id: cryptoId });
              if (response.data) {
                alert("Crypto status changed successfully");
                setTimeout(() => {
                  window.location.reload();
                }, 300);
              }
            } catch (error) {
              console.log(error);
              alert("Something went wrong");
            }
          }}
        />
      </DashboardLayout>
    </>
  );
};

export default InActiveCryptos;
