import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
// import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
// import Fab from "@mui/material/Fab";
// import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Button,
  Card,
  Divider,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import MDBox from "components/MDBox";
import AlertConfirm from "components/AlertConfirm";
import data from "layouts/contests/data/authorsTableData";
import AlertMessage from "components/AlertMessage";
import { createPayoutStructure } from "services/api/gameAPI";
// import AlertMessage from "components/AlertMessage";
// import { createLocation } from "services/api/gameAPI";
// import { countBy } from "lodash";
// import AlertConfirm from "components/AlertConfirm";
// import { createGameType } from "services/api/gameAPI";
const style = {
  position: "absolute",
  top: { xs: "25%", md: "60%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, sm: 700, lg: 800 },
  bgcolor: "transparent",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  display: "flex",
  flexDirection: "column",

  height: { xs: 300, sm: 350, md: 380, lg: 500 },
  overflowY: "scroll",
};

export default function PayoutStructureView({ data }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = async () => {
    try {
      //   const cryptosData = await getCryptos();

      //   const gameConfigData = await getGameConfig();
      //   console.log(cryptosData, gameConfigData);

      //   setCryptos(cryptosData.data.data);

      //   setGameConfig(gameConfigData.data.data);
      setOpen(true);
    } catch (error) {
      // Handle errors here

      console.error("Error fetching data:", error);
    }
  };
  const handleClose = () => setOpen(false);

  console.log(data, "PAAPA");

  return (
    <>
      <Button
        variant="contained"
        sx={{ marginBottom: "20px", textTransform: "none" }}
        onClick={handleOpen}
      >
        View
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Box container sx={style.modal}>
            <Box sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "20px",
                      padding: 3,
                      //   width: "90%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{ m: 1 }}
                        textAlign="center"
                        id="transition-modal-title"
                        variant="h4"
                        component="h2"
                        color={"grey"}
                      >
                        Payout Structure View
                      </Typography>

                      <MDBox>
                        {/* <Stack
                          width={"100%"}
                          direction={"row"}
                          marginBottom={3}
                          gap={2}
                          justifyContent={"start"}
                        >
                          <TextField
                            //   value={minimumPlayers}
                            //   type="number"
                            //   onChange={(e) => setMinimumPlayers(e.target.value)}
                            label="Minimum Players"
                            sx={{ width: 150 }}
                          />
                          <TextField
                            //   value={maximumPlayers}
                            //   onChange={(e) => setMaximumPlayers(e.target.value)}
                            InputProps={{
                              readOnly: true,
                            }}
                            label="Maximum Players"
                            sx={{ width: 150 }}
                          />
                        </Stack>{" "} */}
                        <Typography
                          id="transition-modal-title"
                          variant="h6"
                          component="h2"
                          sx={{ mt: 1, mb: 2 }}
                        >
                          {/* {`Payout Structure of Top ${minimumPlayers}`} */}
                        </Typography>
                        <Card
                          sx={{
                            width: { xs: "240px", md: "575px" },
                            // height: "280px",
                            border: "1px solid white",

                            display: "flex",

                            padding: "10px",
                            paddingLeft: "30px",
                            paddingBottom: "30px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              mt: 1,
                            }}
                          >
                            <Stack direction={"row"} gap={6}>
                              <h5 style={{ color: "white", marginLeft: "18px" }}>From</h5>
                              <h5 style={{ color: "white", marginLeft: "25px" }}>To</h5>
                              <h5 style={{ color: "white", marginLeft: "20px" }}>Percentage</h5>
                              <h5 style={{ color: "white" }}>Total</h5>
                            </Stack>
                          </Box>

                          <Divider variant="middle" />

                          {data.map((dat, i) => (
                            <div
                              style={{
                                color: "white",
                                display: "flex",
                                marginTop: "5px",
                                gap: "20px",
                                paddingLeft: "15px",
                                overflowY: "scroll",
                                width: "100%",
                              }}
                              //   key={key}
                            >
                              <TextField
                                value={dat.from}
                                type="number"
                                name="from"
                                // onChange={(e) => handleInputChange(key, e)}
                                // onChange={(event) => handleInput1Change(key, event)}
                                sx={{ width: 75 }}
                              />
                              <TextField
                                value={dat.to}
                                type="number"
                                name="to"
                                // onChange={(e) => handleInputChange(key, e)}
                                // onChange={(event) => handleInput2Change(key, event)}
                                sx={{ width: 75 }}
                              />
                              <OutlinedInput
                                type="number"
                                name="percentage"
                                value={dat.percentage}
                                sx={{ width: 110, color: "white" }}
                                // onChange={(e) => handleInputChange(key, e)}
                                // onChange={(e) => handlePecentageCount(key, e)}
                                endAdornment={
                                  <InputAdornment position="end">
                                    {" "}
                                    <span style={{ color: "white" }}>%</span>
                                  </InputAdornment>
                                }
                              />
                              <OutlinedInput
                                type="number"
                                name="total"
                                value={dat.total}
                                sx={{ width: 90 }}
                                readOnly={true}
                                // error={dat.total < 0 ? true : false}
                                // onChange={(event) => handleInput3Change(key, event)}
                                endAdornment={
                                  <InputAdornment position="end" sx={{ height: "100%" }}>
                                    <span style={{ color: "white" }}>X</span>
                                  </InputAdornment>
                                }
                              />
                            </div>
                          ))}

                          {/* <div style={{ overflowY: "scroll", width: "100%" }}>{renderData}</div> */}
                          {/* <Divider variant="middle" />

                          <Stack
                            width={"75%"}
                            direction={"row"}
                            marginBottom={3}
                            marginLeft={1.8}
                            gap={2.5}
                            justifyContent={"end"}
                          >
                            <TextField
                              // value={totalPercentage}
                              // error={totalPercentage > 100 ? true : false}
                              InputProps={{
                                readOnly: true,
                              }}
                              sx={{ width: 110 }}
                            />
                            <TextField
                              type="number"
                              // value={totalPlayers}
                              // error={totalPlayers > minimumPlayers ? true : false}
                              InputProps={{
                                readOnly: true,
                              }}
                              sx={{ width: 90 }}
                            /> */}
                          {/* </Stack> */}
                        </Card>
                      </MDBox>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
