import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
// import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
// import Fab from "@mui/material/Fab";
// import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Button,
  Card,
  debounce,
  Divider,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import MDBox from "components/MDBox";
import AlertConfirm from "components/AlertConfirm";
import data from "layouts/contests/data/authorsTableData";
import AlertMessage from "components/AlertMessage";
import { createPayoutStructure } from "services/api/gameAPI";
import { payoutStructureFindByName } from "services/api/gameAPI";
// import AlertMessage from "components/AlertMessage";
// import { createLocation } from "services/api/gameAPI";
// import { countBy } from "lodash";
// import AlertConfirm from "components/AlertConfirm";
// import { createGameType } from "services/api/gameAPI";
const style = {
  position: "absolute",
  top: { xs: "25%", md: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, sm: 700, lg: 800 },
  // bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  height: { xs: 300, sm: 350, md: 380, lg: 500 },
  overflowY: "scroll",
};

export default function ModelBox(prop) {
  //   const [open, setOpen] = React.useState(false);
  const [isAlertMessgOpen, setIsAlertMessg] = React.useState(false);
  const [name, setName] = React.useState("");
  //   const [type, setType] = React.useState("");
  // const [amount, setAmount] = React.useState("");
  const [button, setButton] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const submit = () => {
    setAlertOpen(true);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = async () => {
    try {
      //   const cryptosData = await getCryptos();

      //   const gameConfigData = await getGameConfig();
      //   console.log(cryptosData, gameConfigData);

      //   setCryptos(cryptosData.data.data);

      //   setGameConfig(gameConfigData.data.data);
      setOpen(true);
    } catch (error) {
      // Handle errors here

      console.error("Error fetching data:", error);
    }
  };
  const handleClose = () => setOpen(false);
  //   const [data, setData] = useState(game?.positions);
  const [data, setData] = React.useState([
    {
      from: "",
      to: "",
      percentage: "",
      total: "",
      // position: "",
    },
  ]);

  const [counter, setCounter] = React.useState(1);

  const [minimumPlayers, setMinimumPlayers] = React.useState(0);
  const [maximumPlayers, setMaximumPlayers] = React.useState(0);
  const [payoutName, setPayoutName] = React.useState("");
  const [payoutNameError, setPayoutNameError] = React.useState(null);

  // add new input fields into the positions box
  const [payoutStructure, setPayoutStructue] = React.useState({
    maxPlayer: 0,
    minPlayer: 0,
    structure: [],
  });

  const HandleAddPositions = () => {
    let field = counter;

    // const value = ""; // Example value calculation

    // setPayoutStructue([
    //   {
    //     maxPlayer: maximumPlayers,
    //     minPlayer: minimumPlayers,
    //     structure: {
    //       ...data,
    //     },
    //   },
    // ]);

    setData((prevData) => [
      ...prevData,
      {
        // position: field,
        from: "",
        to: "",
        percentage: 0,
        total: 0,
      },
      // [field]: value,
    ]);
    // eslint-disable-next-line no-plusplus
    setCounter((prevCounter) => prevCounter + 1);
  };

  const handleDelete = (key) => {
    const updatedData = data.filter((_, index) => index !== key);
    setData(updatedData);
    console.log(data);
    setCounter((prevCounter) => prevCounter - 1);
  };

  React.useEffect(() => {
    const num = minimumPlayers;

    if (!isNaN(num)) {
      setMaximumPlayers(num * 2);
    } else {
      setMaximumPlayers(0);
    }
  }, [minimumPlayers]);

  //   const handleInput1Change = (index, event) => {
  //     const updatedValue = event.target.value.trim() === "" ? 0 : Number(event.target.value);
  //     // setFrom(updatedValue);
  //     setData((prevData) => {
  //       const newData = [...prevData];
  //       newData[index] = {
  //         ...newData[index],
  //         from: updatedValue,
  //       };
  //       return newData;
  //     });
  //   };

  //   const handleInput2Change = (index, event) => {
  //     const updatedValue = event.target.value.trim() === "" ? 0 : Number(event.target.value);
  //     // setTo(updatedValue);
  //     setData((prevData) => {
  //       const newData = [...prevData];
  //       newData[index] = {
  //         ...newData[index],
  //         to: updatedValue,
  //       };
  //       return newData;
  //     });
  //   };

  //   const handleInput3Change = (index, event) => {
  //     // const updatedValue = Number(event.target.value);
  //     // console.log(updatedValue);
  //     setData((prevData) => {
  //       const newData = [...prevData];
  //       newData[index] = {
  //         ...newData[index],
  //         total: event.target.value,
  //       };
  //       return newData;
  //     });
  //   };

  //   const handlePecentageCount = (index, event) => {
  //     const updatedValue = event.target.value.trim() === "" ? 0 : Number(event.target.value);
  //     setData((prevData) => {
  //       const newData = [...prevData];
  //       newData[index] = {
  //         ...newData[index],
  //         percentage: updatedValue,
  //       };
  //       return newData;
  //     });
  //   };

  const [totalPercentage, setTotalPercentage] = React.useState(0);

  React.useEffect(() => {
    const calculateTotalPercentage = () => {
      const total = data.reduce((sum, field) => sum + field.percentage, 0);
      return setTotalPercentage(total);
    };
    calculateTotalPercentage();
  }, [data]);

  const [totalPlayers, setTotalPlayers] = React.useState(0);

  React.useEffect(() => {
    const calculateTotalPlayers = () => {
      const count = data.reduce((sum, field) => sum + field.total, 0);
      return setTotalPlayers(count);
    };
    calculateTotalPlayers();
  }, [data]);

  const calculateNumberBetween = (from, to) => {
    if (from === to) {
      return 1;
    } else {
      const sum = to - from + 1;
      return sum;
    }
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;

    setData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [name]: parseFloat(value),
      };

      if (name === "from" || name === "to") {
        const calculatedValue = calculateNumberBetween(newData[index].from, newData[index].to);
        newData[index].total = calculatedValue;
      }

      return newData;
    });

    // Calculate the value for field3 and update it
  };

  React.useEffect(() => {
    setPayoutStructue({
      maxPlayer: maximumPlayers,
      minPlayer: parseInt(minimumPlayers),
      structure: {
        ...data,
      },
    });
  }, [data, maximumPlayers, minimumPlayers]);

  const renderData = data?.map((dat, key) => (
    <div
      style={{
        color: "white",
        display: "flex",
        marginTop: "5px",
        gap: "20px",
        paddingLeft: "15px",
        // overflowY: "scroll",
        width: "100%",
      }}
      key={key}
    >
      <TextField
        value={dat.from}
        type="number"
        name="from"
        onChange={(e) => handleInputChange(key, e)}
        // onChange={(event) => handleInput1Change(key, event)}
        sx={{ width: 75 }}
      />
      <TextField
        value={dat.to}
        type="number"
        name="to"
        onChange={(e) => handleInputChange(key, e)}
        // onChange={(event) => handleInput2Change(key, event)}
        sx={{ width: 75 }}
      />
      <OutlinedInput
        type="number"
        name="percentage"
        value={dat.percentage}
        sx={{ width: 110, color: "white" }}
        onChange={(e) => handleInputChange(key, e)}
        // onChange={(e) => handlePecentageCount(key, e)}
        endAdornment={
          <InputAdornment position="end">
            {" "}
            <span style={{ color: "white" }}>%</span>
          </InputAdornment>
        }
      />
      <OutlinedInput
        type="number"
        name="total"
        value={dat.total}
        sx={{ width: 90 }}
        readOnly={true}
        error={dat.total < 0 ? true : false}
        // onChange={(event) => handleInput3Change(key, event)}
        endAdornment={
          <InputAdornment position="end" sx={{ height: "100%" }}>
            <span style={{ color: "white" }}>X</span>
          </InputAdornment>
        }
      />

      {key >= 1 ? <Button onClick={() => handleDelete(key)}>delete</Button> : ""}
    </div>
  ));

  const nameDebounceChange = React.useCallback(
    debounce((inputValue) => {
      checkPayoutName(inputValue);
    }, 300),
    []
  );

  const checkPayoutName = async (inputValue) => {
    try {
      setPayoutNameError(null);
      if (!inputValue) return;

      const response = await payoutStructureFindByName({
        name: inputValue,
      });
      if (response?.data?.data) {
        setPayoutNameError("Payout name already exist");
      } else {
        setPayoutNameError(null);
      }
    } catch (error) {
      setPayoutNameError(error?.message);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ marginBottom: "20px", textTransform: "none" }}
        onClick={handleOpen}
      >
        Create
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Box container sx={style.modal}>
            <Box sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "20px",
                      padding: 3,
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{ m: 3 }}
                        textAlign="center"
                        id="transition-modal-title"
                        variant="h4"
                        component="h2"
                      >
                        Payout Structure
                      </Typography>

                      <MDBox>
                        <Stack
                          width={"100%"}
                          direction={"row"}
                          marginBottom={3}
                          gap={2}
                          justifyContent={"start"}
                        >
                          <TextField
                            value={payoutName}
                            type="text"
                            onChange={(e) => {
                              setPayoutName(e.target.value);
                              nameDebounceChange(e.target.value);
                            }}
                            label="Name"
                            sx={{ width: 150 }}
                            error={!!payoutNameError}
                            helperText={payoutNameError ?? null}
                          />
                          <TextField
                            value={minimumPlayers}
                            type="number"
                            onChange={(e) => setMinimumPlayers(e.target.value)}
                            label="Minimum Players"
                            sx={{ width: 150 }}
                          />
                          <TextField
                            value={maximumPlayers}
                            onChange={(e) => setMaximumPlayers(e.target.value)}
                            InputProps={{
                              readOnly: true,
                            }}
                            label="Maximum Players"
                            sx={{ width: 150 }}
                          />
                        </Stack>{" "}
                        <Typography
                          id="transition-modal-title"
                          variant="h6"
                          component="h2"
                          sx={{ mt: 1, mb: 2 }}
                        >
                          {`Payout Structure of Top ${minimumPlayers}`}
                        </Typography>
                        <Card
                          sx={{
                            width: { xs: "240px", md: "575px" },
                            // height: "280px",
                            border: "1px solid white",
                            overflowX: "scroll",
                            display: "flex",
                            padding: "10px",
                          }}
                        >
                          {/* <Box sx={{ width: "100%" }}> */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              mt: 1,
                            }}
                          >
                            <Stack direction={"row"} gap={6}>
                              <h5 style={{ color: "white", marginLeft: "18px" }}>From</h5>
                              <h5 style={{ color: "white", marginLeft: "25px" }}>To</h5>
                              <h5 style={{ color: "white", marginLeft: "20px" }}>Percentage</h5>
                              <h5 style={{ color: "white" }}>Total</h5>
                            </Stack>

                            <Button
                              onClick={HandleAddPositions}
                              // disabled={totalPercentage === 100 ? true : false}
                              disabled={totalPlayers == minimumPlayers ? true : false}
                              variant="contained"
                              size="small"
                              sx={{ width: "40px", height: "20px", marginRight: "20px" }}
                            >
                              add
                            </Button>
                          </Box>
                          {/* {positions?.map((pos, key) => (
                      <h3>{key + 1}</h3>
                    ))} */}
                          <Divider variant="middle" />
                          <div style={{ width: "100%" }}>{renderData}</div>
                          <Divider variant="middle" />

                          <Stack
                            width={"75%"}
                            direction={"row"}
                            marginBottom={3}
                            marginLeft={1.8}
                            gap={2.5}
                            justifyContent={"end"}
                          >
                            <TextField
                              value={totalPercentage}
                              error={totalPercentage > 100 ? true : false}
                              InputProps={{
                                readOnly: true,
                              }}
                              sx={{ width: 110 }}
                            />
                            <TextField
                              type="number"
                              value={totalPlayers}
                              error={totalPlayers > minimumPlayers ? true : false}
                              InputProps={{
                                readOnly: true,
                              }}
                              sx={{ width: 90 }}
                            />
                          </Stack>
                          {/* </Box> */}
                        </Card>
                      </MDBox>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <MDButton
                        type="submit"
                        onClick={submit}
                        sx={{ textAlign: "center" }}
                        variant="gradient"
                        color="primary"
                        disabled={totalPercentage !== 100 ? true : false}
                      >
                        Create
                      </MDButton>
                    </Box>
                    {/* </Box> */}
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <AlertConfirm
              open={alertOpen}
              title="Create"
              context="Do you want to create a payout structure?"
              onClose={() => setAlertOpen(!alertOpen)}
              onAccept={async () => {
                try {
                  // console.log(payoutStructure, "CREATE");
                  console.log(data, "Data");

                  if (payoutNameError) {
                    return alert(payoutNameError);
                  }

                  if (totalPlayers > minimumPlayers) {
                    setIsAlertMessg(true);
                    return setAlertOpen(!alertOpen);
                  }

                  const body = {
                    maxPlayer: maximumPlayers,
                    minPlayer: parseInt(minimumPlayers),
                    structure: data,
                    name: payoutName,
                  };
                  console.log(body, "Body");
                  const payout = await createPayoutStructure(body);
                  if (payout) {
                    alert("Success! New Payout structure created.");
                    setOpen(false);
                    setTimeout(() => {
                      window.location.reload();
                    }, 300);
                    // window.location.reload()
                  }
                } catch (error) {
                  if (error?.response?.data?.error) {
                    alert(error?.response?.data?.error);
                  } else {
                    alert("Something went wrong. Please try again.");
                  }
                  console.log(error);
                }
              }}
            />

            {/* <MDButton
              disabled={button}
              onClick={submit}
              sx={{ mt: 4 }}
              size="small"
              fullWidth={true}
              variant="gradient"
              color="primary"
            >
              Create
            </MDButton> */}

            <AlertMessage
              open={isAlertMessgOpen}
              setOpen={setIsAlertMessg}
              severity="Error"
              success="error"
              message="The total should be equal to the minimum player count"
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
