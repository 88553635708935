import { IconButton } from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <>
      {" "}
      <IconButton aria-label="delete" color="secondary" onClick={() => navigate(-1)}>
        <ArrowBackIcon color="#fff" />
      </IconButton>
    </>
  );
};

export default BackButton;
