/**
=========================================================
* Crypto Champs React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // Paper,
  TablePagination,
  // List,
  // ListItem,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
// import MDButton from "components/MDButton";
// import { useNavigate } from "react-router-dom";
// import { SelectedCryptosData } from "services/api/gameAPI";
// import { searchUser } from "services/api/gameAPI";
// import { useStyles } from "layouts/tokenDistribution";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
// import ProgressLoading from "components/ProgressLoading/ProgressLoading";
import { io } from "socket.io-client";
import { CommonData } from "constant/common";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableHead = styled(TableHead)(() => ({
  display: "table-header-group",
}));

function SelectedCryptos() {
  const [cryptos, setCryptos] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [term, setTerm] = useState("");
  const [query, setQuery] = useState("");

  const navigate = useNavigate();

  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     let response = [];
  //     try {
  //       response = await SelectedCryptosData({ page: page + 1, limit, term });
  //       console.log(response.data, "dd");
  //       setCryptos(response.data.data.cryptos);

  //       setCount(response.data.data.total);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  // }, [page, limit, term]);

  useEffect(() => {
    const socket = io("wss://api.cryptochamps.io");
    // const socket = io(CommonData.cryptoSocket);
    // const socket = io("wss://dev-staging-api.cryptochamps.io");
    // const socket = io("http://localhost:3005");
    // Emit the JSON body
    const requestData = {
      page: page + 1,
      limit: limit,
      isDisabled: false,
      title: term,
    };

    const fetchDataAndUpdate = (socket, requestData) => {
      socket.emit("GETALL_CRYPTOS_ADMIN", requestData);

      socket.on("GETALL_CRYPTOS_ADMIN", (ackData) => {
        // console.log("Received live data update:", ackData);
        setCryptos(ackData.cryptos);

        setCount(ackData.total);
      });
    };
    // Initial fetch of data
    fetchDataAndUpdate(socket, requestData);
    // Fetch data every 2 seconds
    const intervalId = setInterval(() => {
      fetchDataAndUpdate(socket, requestData);
    }, 2000);

    // Clean up the socket connection
    return () => {
      clearInterval(intervalId);
      socket.disconnect();
    };
  }, [limit, page, term]);

  console.log(cryptos, "RES");

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };
  const handleTitle = async (e) => {
    const newQuery = e.target.value;

    setTerm(newQuery);
    setQuery(newQuery);
    console.log(query);
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {/* {loading ? (
        <ProgressLoading />
      ) : ( */}
      <>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "12px -1px",
                }}
              >
                <MDBox margin="20px" pr={1}>
                  <MDInput label="Search cryptos" value={query} onChange={handleTitle} />
                </MDBox>

                <MDTypography component="label" style={{ marginRight: "120px" }}></MDTypography>

                <div style={{ margin: "20px" }}>
                  {/* {cryptos.length !== 0 ? ( */}
                  {/* <MDButton
                        variant="contained"
                        onClick={() => navigate("/addcryptos")}
                        color="primary"
                      >
                        Add Cryptos
                      </MDButton> */}
                  {/* ) : (
                        <div></div>
                      )} */}
                  <MDButton
                    variant="contained"
                    onClick={() => navigate("/inactive-cryptos")}
                    color="info"
                    sx={{
                      fontSize: 10,
                    }}
                  >
                    View Last 24H Inactive Cryptos
                  </MDButton>
                </div>
              </div>
              {/* {query === "" ? (
                      ""
                    ) : ( */}
              {/* <div id="search-card" className={classes.searchCard}>
                      <Paper>
                        <List id="suggestion-list">
                          {suggestions.map((suggestion) => (
                            <ListItem
                              key={suggestion._id}
                              button
                              className={classes.suggestionItem}
                              // onClick={() => handleSuggestionClick(suggestion)}
                            >
                              {suggestion?.name}
                            </ListItem>
                          ))}
                        </List>
                      </Paper>
                    </div> */}
              {/* )} */}

              <TableContainer>
                {cryptos.length === 0 ? (
                  <div
                    style={{
                      color: "#FFF",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "300px",
                    }}
                  >
                    There are no records at the moment.
                  </div>
                ) : (
                  <Table>
                    <StyledTableHead>
                      <TableRow>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Symbol</StyledTableCell>
                        <StyledTableCell> Price</StyledTableCell>
                        <StyledTableCell>Price Change Percentage 24hr</StyledTableCell>
                      </TableRow>
                    </StyledTableHead>

                    <TableBody>
                      {cryptos?.map((crypto) => (
                        <StyledTableRow key={crypto._id}>
                          <StyledTableCell sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Avatar src={crypto.image} />
                            {crypto.name}
                          </StyledTableCell>
                          <StyledTableCell>{crypto.symbol}</StyledTableCell>
                          <StyledTableCell>${crypto.price}</StyledTableCell>
                          <StyledTableCell>{crypto.price_change_percentage_24h}%</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={count}
              labelRowsPerPage="Rows per page:"
              style={{ color: "white" }} // Change the color here
              rowsPerPage={limit}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
            />
          </Grid>
        </Grid>
        <Footer />
      </>
      {/* )} */}
    </DashboardLayout>
  );
}
export default SelectedCryptos;
