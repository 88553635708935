import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Icon,
  Alert,
  // Badge,
} from "@mui/material";
import { Container, styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import USDCToken from "../../Contract/USDC.json";
import MDTypography from "components/MDTypography";

//web3
import TT4LToken from "../../Contract/TT4L.json";
import { ethers } from "ethers";
// import { useNavigate } from "react-router-dom";
import { getBlockChainTransaction } from "services/api/gameAPI";
import { updateBlockchainTransaction } from "services/api/gameAPI";
import { getBlockChianConfig } from "services/api/gameAPI";

import MDBadge from "components/MDBadge";
import { convertUsdcAndWeth } from "services/api/gameAPI";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
}));

export const T4Address = "0xF083b8d6741c2c12c13864E94Ad05F697f315BAB";
function UsdcWithdraw() {
  // let navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [term, setTerm] = useState("");
  const [stage, setStage] = useState(0);
  // const [idValues, setIdValues] = useState([]);

  //

  // const [transferInfo, setTransferInfo] = useState({
  //   recipient: null,
  //   amount: null,
  // });
  // const [txProcessing, toggleTxProcessing] = useState(false);
  const [contractInfo, setContractInfo] = useState({
    address: "-",
    tokenName: "-",
    tokenSymbol: "-",
    totalSupply: "-",
  });

  const [contractAddress, setContractAddress] = useState();
  const [blockChainConfigData, setBlockChainConfigData] = useState();

  // const [balanceInfo, setBalanceInfo] = useState({
  //   address: "-",
  //   balance: "-",
  // });

  useEffect(() => {}, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getBlockChainTransaction({ page: page + 1, limit });
        console.log(res, "RES");
        setUsers(res.data?.data?.data);
        setCount(res.data?.data?.total);
        // const extractedIds = res.data?.data?.transaction.map((item) => item._id);
        // setIdValues(extractedIds);
        // setCount(res.data?.data?.transaction?.filter((x) => x.verified === false).length);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [page, limit, term]);

  useEffect(() => {
    const fetchWallet = async () => {
      try {
        const res = await getBlockChianConfig();
        setContractAddress(res.data?.contractAddress);
        setBlockChainConfigData(res.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchWallet();
  }, []);

  // useEffect(() => {
  //   const fetchWallet = async () => {
  //     try {
  //       const res = await getBlockChianConfig();
  //       console.log(res);

  //       setContractAddress(res.data?.contractAddress);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   fetchWallet();
  // }, []);

  console.log(contractAddress, "CA");
  console.log(users, "User");

  // const noOfPages = Math.ceil(count / limit);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    // const erc20 = new ethers.Contract(T4Address, TT4LToken, provider);
    const erc20 = new ethers.Contract(T4Address, TT4LToken, provider);
    console.log(erc20, "ERC");
    try {
      const tokenName = await erc20.name();
      const tokenSymbol = await erc20.symbol();
      const totalSupply = await erc20.totalSupply();
      await setContractInfo({
        address: T4Address,
        tokenName,
        tokenSymbol,
        totalSupply,
      });
      await console.log(contractInfo, "CI");
      await setStage(stage + 1);
      await setLoading(false);
    } catch (err) {
      alert("No wallet found");
      console.log(err);
    }
  };
  //   console.log(contractInfo, "CON IFO");
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };
  const handletitle = (e) => {
    setTerm(e.target.value);
    setPage(0);
  };

  console.log(contractAddress);

  const handleTransfer = async (id, user_wallet_address, tokenAmount) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();

    // signer.sendTransaction();

    try {
      // const usdcContractAddress = "0xaf88d065e77c8cC2239327C5EDb3A432268e5831";

      let usdcContractAddress;

      await blockChainConfigData?.paymentMethod.map((e) => {
        if (e?.symbol === "USDC") usdcContractAddress = e?.contractAddress;
      });

      if (!usdcContractAddress) {
        // return alert("Oops! Something went wrong. Please try again later");
        return alert("USDC Contract Address Not Exist!");
      }

      console.log(usdcContractAddress);

      const usdcContract = new ethers.Contract(usdcContractAddress, USDCToken, signer);

      // console.log(usdcContract, "usdcContract");
      // console.log(user_wallet_address);
      const transaction = await usdcContract.transfer(
        user_wallet_address,
        // Number(tokenAmount) * 1e18,
        ethers.utils.parseUnits(tokenAmount, 18),
        // Number(1) * 1e12,
        {
          gasLimit: 300000,
        }
      );

      /*
      //====
      console.log(tokenAmount);
      const convert = await convertUsdcAndWeth({
        amount: Number(tokenAmount),
        isWeth: false,
      });

      if (!convert) {
        return;
      }
      console.log(convert?.data);
      const amount = ethers.utils.parseEther(`${convert?.data?.weth}`); //wei

      // Estimate gas cost of the transaction
      const estGas = await signer.estimateGas({
        to: user_wallet_address,
        value: amount,
      });

      // console.log("estGas", estGas);

      // Add a safety buffer to the estimated gas limit
      const gasLimitWithBuffer = estGas.mul(110).div(100); // Increase by 10%

      console.log(gasLimitWithBuffer, "gasLimitWithBuffer");

      const transaction = await signer.sendTransaction({
        to: user_wallet_address,
        value: amount,
        gasLimit: gasLimitWithBuffer,
      });

      //=====

      */
      const receipt = await transaction.wait();
      console.log("Transaction hash:", receipt?.transactionHash, receipt?.status, receipt);
      if (receipt?.transactionHash && receipt?.status == 1) {
        const dataOne = {
          id: id,
          transactionHash: receipt?.transactionHash,
        };
        const res = await updateBlockchainTransaction(dataOne);
        alert("Success! Withdrawal Approved.");
        console.log(res, "RESPONCE");
        setTimeout(() => {
          window.location.reload();
        }, 300);
        return res;
      }
    } catch (e) {
      alert("Oops! Something went wrong. Unable to process the withdrawal at this time.");
      console.log(e, "ERROR USDC");
      // alert("MetaMask not detected");
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {loading ? (
        <Container
          sx={{
            width: "100%",
            height: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ color: "#FFF" }}>Loading...</div>
        </Container>
      ) : (
        <>
          <Grid container spacing={6}>
            {users.length === 0 ? (
              <Container
                sx={{
                  width: "100%",
                  height: "80vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ color: "#FFF" }}>There are no records at the moment.</div>
              </Container>
            ) : (
              <>
                <Grid item xs={12}>
                  <Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // padding: "12px -1px",
                        marginBottom: "20px",
                      }}
                    >
                      <MDBox margin="20px" pr={1}>
                        <MDInput
                          label="Search..."
                          value={term}
                          size="small"
                          onChange={handletitle}
                        />
                      </MDBox>
                      <MDTypography
                        component="label"
                        style={{ marginLeft: "0%", color: "#FFF", marginTop: "10px" }}
                      >
                        USDC Withdrawal Requests
                      </MDTypography>
                      <MDTypography component="label" style={{ padding: "0px 80px" }}>
                        {/* Add label text here */}
                      </MDTypography>
                      {/* {username === "admin" && ( */}
                      {/* <div style={{ margin: "20px" }}>
                        {stage !== 0 ? (
                          <MDButton variant="contained" onClick={totalData} color="primary">
                            Create Root
                          </MDButton>
                        ) : (
                          <div></div>
                        )}
                      </div> */}
                      {/* )} */}
                    </div>

                    <Alert variant="standard" severity="info" sx={{ marginBottom: 8, marginX: 2 }}>
                      Once approved, the pending status will update within 3 seconds
                    </Alert>

                    {/* {stage === 0 ? (
                      <div style={{ marginLeft: "50%", marginBottom: "10%" }}>
                        <Button big onClick={handleSubmit}>
                          {stage === 0 ? "Initiate Wallet " : "Connected "}
                          <Icon>
                           
                          </Icon>
                        </Button>
                      </div>
                    ) : stage === 1 ? ( */}
                    <TableContainer>
                      <Table>
                        <StyledTableHead>
                          <TableRow>
                            <StyledTableCell>Game ID</StyledTableCell>
                            <StyledTableCell>Wallet Address</StyledTableCell>
                            <StyledTableCell>Prize Money</StyledTableCell>
                            <StyledTableCell>User Name</StyledTableCell>
                            <StyledTableCell>Created At</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Action</StyledTableCell>
                            {/* <StyledTableCell>Pay</StyledTableCell> */}

                            {/* <StyledTableCell>View</StyledTableCell> */}
                            {/* Add more table headers as needed */}
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {users?.map((user) => (
                            <StyledTableRow key={user?._id}>
                              <StyledTableCell>
                                {user?.gameInstance
                                  ?.substring(user?.gameInstance?.length - 7)
                                  .toUpperCase()}
                              </StyledTableCell>
                              <StyledTableCell>{user?.walletAddress}</StyledTableCell>
                              <StyledTableCell>
                                {user?.token?.replace(/(\.\d{10})\d*/, "$1")}
                              </StyledTableCell>
                              <StyledTableCell>{user?.userId?.username}</StyledTableCell>
                              <StyledTableCell>
                                {new Date(user?.createdAt).toLocaleString("en-US", {
                                  timeZone: "America/New_York",
                                })}
                              </StyledTableCell>

                              <StyledTableCell>
                                <MDBox ml={-1}>
                                  <MDBadge
                                    badgeContent={user?.transactionStatus}
                                    color={
                                      user?.transactionStatus === "completed" ? "success" : "info"
                                    }
                                    variant="gradient"
                                    size="sm"
                                  />
                                </MDBox>
                              </StyledTableCell>

                              <StyledTableCell>
                                {user.isAdminVerified ? (
                                  <></>
                                ) : (
                                  <MDButton
                                    variant="gradient"
                                    color="error"
                                    onClick={() =>
                                      handleTransfer(
                                        user?._id,
                                        user?.walletAddress,
                                        user?.token?.replace(/(\.\d{10})\d*/, "$1")
                                      )
                                    }
                                    size="small"
                                  >
                                    Approve
                                  </MDButton>
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* ) : (
                      <p
                        style={{
                          fontFamily: "sans-serif",
                          color: "white",
                          fontWeight: "bolder",
                          paddingLeft: "20%",
                        }}
                      >
                        Transaction Sent... Root sent to verification
                      </p>
                    )} */}
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={count}
                    style={{ color: "white" }}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Footer />
        </>
      )}
    </DashboardLayout>
  );
}
export default UsdcWithdraw;
