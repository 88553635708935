const permissionsData = [
  // {
  //   "_id": "64802121f372d60b62dc4403",
  //   "name": "Post",
  //   "action": [
  //     "read",
  //     "block",

  //   ],

  // },
  // {
  //   "_id": "64802193f372d60b62dc4408",
  //   "name": "Comment",
  //   "action": [
  //     "read",
  //     "block",

  //   ],

  // },
  // {
  //   "_id": "648070b00990513b0ccdda74",
  //   "name": "Interest",
  //   "action": [
  //     "read",
  //     "write",
  //     "edit",
  //     "delete"
  //   ],

  // },

  // {
  //   "_id": "6482f900f060ffc3a52ec2b4",
  //   "name": "Profile Type",
  //   "action": [
  //     "read",
  //     "write",
  //     "edit",
  //     "delete"
  //   ],

  // },
  // {
  //   "_id": "6482f914f060ffc3a52ec2b6",
  //   "name": "420 Type",
  //   "action": [
  //     "read",
  //     "write",
  //     "edit",
  //     "delete"
  //   ],

  // },

  // {
  //   "_id": "6482f97af060ffc3a52ec2be",
  //   "name": "Favorite Strain",
  //   "action": [
  //     "read",
  //     "write",
  //     "edit",
  //     "delete"
  //   ],

  // },
  // {
  //   "_id": "6482f991f060ffc3a52ec2c0",
  //   "name": "Activities",
  //   "action": [
  //     "read",
  //     "write",
  //     "edit",
  //     "delete"
  //   ],

  // },
  // {
  //   "_id": "6482f9a3f060ffc3a52ec2c2",
  //   "name": "Cannibal Skill",
  //   "action": [
  //     "read",
  //     "write",
  //     "edit",
  //     "delete"
  //   ],

  // },
  // {
  //   "_id": "6482f9c6f060ffc3a52ec2c4",
  //   "name": "Referral Code",
  //   "action": [
  //     "read",
  //     "write",
  //     "edit",
  //     "delete"
  //   ],

  // },
  {
    "_id": "6484987b6543186eadeb9c609",
    "name": "Access Location",
    "action": [
      "write",
      "read",
      "edit",
      "delete",
      // "block"
    ],

  },
  {
    "_id": "6484987b6543186eadeb9c609",
    "name": "Game Management",
    "action": [
      "write",
      "read",
      "edit",
      "delete",
      // "block"
    ],

  },
  {
    "_id": "6484987b6543186eadeb9c61",
    "name": "User",
    "action": [
      "read",
      "write",
      "edit",
      "delete",
      
    ],

  },
  // {
  //   "_id": "6484987b65233186eadeb9c6e",
  //   "name": "User Role",
  //   "action": [
  //     "read",
  //     "write",
  //     "edit",
  //     "delete",
      
  //   ],

  // },
  // {
  //   "_id": "6482f8e3f060ffc3a52ec2b2",
  //   "name": "User",
  //   "action": [
  //     "read",
  //     "edit",
  //     "delete",
  //     "block"
  //   ],

  // },
  {
    "_id": "648498656543186eadeb9c6c",
    "name": "Wallet",
    "action": [
      "read"
    ],

  },
  {
    "_id": "6484987b6543186eadeb9c21",
    "name": "Ledger",
    "action": [
      "read"
    ],

  },
  // {
  //   "_id": "6484987b6543186eadeb9c6e",
  //   "name": "Distribution",
  //   "action": [
  //     "read",
  //     "write",
     
  //   ],

  // },
  // {
  //   "_id": "648498a36543186eadeb9c70",
  //   "name": "Push Notification",
  //   "action": [
     
  //     "write"
  //   ],

  // },
  ,
  {
    "_id": "648498a36543186eadeb9c70",
    "name": "Reward",
    "action": [
     "read",
      "write"
    ],

  },
  // {
  //   "_id": "648498b56543186eadeb9c72",
  //   "name": "Admin Profile",
  //   "action": [
  //     "read",
  //     "edit"
  //   ],

  // },
  // {
  //   "_id": "648498ca6543186eadeb9c74",
  //   "name": "Configuration",
  //   "action": [
  //     "read",
  //     "edit"
  //   ],

  // },
  // {
  //   "_id": "648498f76543186eadeb9c77",
  //   "name": "Badge",
  //   "action": [
  //     "read",
  //     "write",
  //     "edit",
  //     "delete"
  //   ],

  // },
  // {
  //   "_id": "648499106543186eadeb9c79",
  //   "name": "News Feed",
  //   "action": [
  //     "read",
  //     "write",
  //     "edit",
  //     "delete"
  //   ],

  // },
  // {
  //   "_id": "6484994b6543186eadeb9c7c",
  //   "name": "T4L Rule",
  //   "action": [
  //     "read",
  //     "edit"
  //   ],

  // },
  // {
  //   "_id": "6484997e6543186eadeb9c81",
  //   "name": "Upload Image",
  //   "action": [
  //     "read",
  //     "edit"
  //   ],

  // },
  // {
  //   "_id": "648499926543186eadeb9c83",
  //   "name": "Flagged User",
  //   "action": [
  //     "read",
  //     "delete"
  //   ],

  // }
];
export default permissionsData;