import React, { useState, useEffect } from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  TextField,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  IconButton,
  InputAdornment,
  TableRow,
  TableHead,
} from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {  styled } from "@mui/system";
import { getRole, getAdmin } from "services/api/gameAPI";
import { createAdmin } from "services/api/gameAPI";
import AlertConfirm from "components/AlertConfirm";
import { deleteAdmin } from "services/api/gameAPI";
import { UN_AUTH_STATUS } from "lib/axiosConfig";

const StyledForm = styled("form")`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 36px;
  min-width: 400px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
`;

const CenterDiv = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledInput = styled(TextField)`
  width: 105%;
  padding: 10px;
  
  margin-bottom: 10px;
`;

const StyledSelect = styled("select")`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
`;

const StyledButton = styled("button")`
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableHead = styled(TableHead)(() => ({
  display: "table-header-group",
}));
const AdminPage = () => {
  const [payloadData, setPayloadData] = useState("");
  const [userId, setUserId] = useState("");
  let [alertOpen, setAlertOpen] = useState(false);
  let [alertOpenOne, setAlertOpenOne] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedPermission, setSelectedPermission] = useState("");
  const [roles, setRoles] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = React.useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    fetchAdmins(currentPage);
  }, [currentPage]);

  const fetchAdmins = async (page) => {
    try {
      setLoading(false);
      const response = await getAdmin();
      const filteredAdmins = response.data.filter((admin) => admin.username !== "superadmin");
      setAdmins(filteredAdmins);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      console.log("An error occurred:", error);
    }
  };

  useEffect(() => {
    const fetchPermissionsData = async () => {
      try {
        const response = await getRole();
        setRoles(response.data);
      } catch (error) {
        alert(`${UN_AUTH_STATUS}`);
          window.history.back();
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissionsData();
  }, []);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const userHandle = (id) => {
    setAlertOpen(true);
    setUserId(id);
  };
 

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRoleChange = async (e) => {
    setSelectedRole(e.target.value);
    const role = await roles.find((item) => item.role === e.target.value);
    setSelectedPermission(role?.permissions);
  };
  // const alertHandler = (title, id) => {
  //   // alert(`Confirm Delete ${x}`)
  //   confirmAlert({
  //     message: ` Are you sure to delete "${title}"?`,
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => deleteAdmin(id),
  //       },
  //       {
  //         label: "No",
  //       },
  //     ],
  //   });
  // };
  // const deleteAdmin = async (id) => {
  //   try {
  //     const response = await deleteAdmin(id)
  //     console.log(response.data);
  //     window.location.reload(false);
  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      username: username,
      password: password,
      role: selectedRole,
      permissions: selectedPermission,
    };
    await setPayloadData(payload)
    setAlertOpenOne(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <CenterDiv className="container mt-4">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div>
              <StyledForm onSubmit={handleSubmit}>
                <StyledInput
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={handleUsernameChange}
                  required
                />

                <TextField
                  variant="outlined"
                  style={{ marginBottom: "12px" }}
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={handlePasswordChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  required
                />

                <StyledSelect value={selectedRole} onChange={handleRoleChange} required>
                  <option value="" disabled>
                    Select role
                  </option>
                  {roles.map((role) => (
                    <option key={role._id} value={role.role}>
                      {role.role}
                    </option>
                  ))}
                </StyledSelect>

                <StyledButton type="submit">Create Admin</StyledButton>
              </StyledForm>
            </div>
          </div>
        </div>
      </CenterDiv>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          {admins?.length == 0 ? (
            <div className="text-center">
              <br />
              There is no admin
            </div>
          ) : (
            <TableContainer>
              <Table className="table table-bordered">
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCell>Username</StyledTableCell>
                    <StyledTableCell>Role</StyledTableCell>
                    <StyledTableCell>Edit</StyledTableCell>
                    <StyledTableCell>Delete</StyledTableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {admins?.map((admin) => (
                    <StyledTableRow key={admin._id}>
                      <StyledTableCell>{admin.username}</StyledTableCell>
                      <StyledTableCell>{admin.role}</StyledTableCell>
                      <StyledTableCell>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm rounded-0"
                          onClick={() => {
                            navigate(`/WebUser/Edit/${admin._id}`, { state: admin });
                          }}
                        >
                          <GridViewIcon />
                        </button>
                      </StyledTableCell>
                      <StyledTableCell>
                        {" "}
                        <button
                          type="button"
                          className="btn btn-danger btn-sm rounded-0"
                          onClick={() => userHandle(admin._id)}
                        >
                          <DeleteIcon />
                        </button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      )}
      <AlertConfirm
        open={alertOpen}
        title="Delete"
        context="Do you want to delete ?"
        onClose={() => setAlertOpen(!alertOpen)}
        onAccept={async () => {
          try {
            const response = await deleteAdmin(userId);
            console.log(response.data);
            window.location.reload(false);
          } catch (error) {
            console.error("An error occurred:", error);
          }
        }}
      />
      <AlertConfirm
        open={alertOpenOne}
        title="Delete"
        context="Do you want to create new user ?"
        onClose={() => setAlertOpenOne(!alertOpenOne)}
        onAccept={async () => {
          try {
            const data = await createAdmin(payloadData);
            console.log(data);
           // alert("Successfully created");
            window.location.reload(false);
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </DashboardLayout>
  );
};

export default AdminPage;
