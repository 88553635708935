import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
// import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Grid, TextField } from "@mui/material";
// import AlertMessage from "components/AlertMessage";
import { createLocation } from "services/api/gameAPI";
// import { countBy } from "lodash";
import AlertConfirm from "components/AlertConfirm";
const style = {
  modal: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    bgcolor: "black",
    boxShadow: 24,
    pt: 1,
    pb: 3,
    pr: 4,
    pl: 4,
  },
  card: {
    display: "flex",
    gap: 3,
    width: "100%",
    justifyContent: "space-between",
  },
};

export default function ModalBox(prop) {
  const [open, setOpen] = React.useState(false);
  // const [isOpen, setIsOpen] = React.useState(false);
  // const [name, setName] = React.useState("");
  const [country, setCountry] = React.useState("");
  // const [amount, setAmount] = React.useState("");
  const [button, setButton] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const submit = () => {
    setAlertOpen(true);
  };

  React.useEffect(() => {
    if (!country) {
      setButton(true);
    } else {
      setButton(false);
    }
  }, [country]);

  return (
    <div>
      <Fab
        onClick={handleOpen}
        style={{ position: "fixed", zIndex: 99, right: "30px", bottom: "70px" }}
        size="medium"
        color="primary"
        aria-label="add"
      >
        <AddIcon />
      </Fab>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box container sx={style.modal}>
            <Typography
              sx={{ m: 3 }}
              textAlign="center"
              id="transition-modal-title"
              variant="h4"
              component="h2"
            >
              Add Service Location
            </Typography>
            <Grid container gap={3}>
              <Grid item sm={12}>
                <TextField
                  onChange={(e) =>
                    setCountry(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))
                  }
                  size="small"
                  fullWidth
                  label="Country"
                />
              </Grid>

              {/* <Grid item sm={12} >
              <TextField onChange={(e)=> setAmount(e.target.value)} size='small' fullWidth label="amount" />
            </Grid> */}
              <Grid container flexWrap="nowrap" gap={1}></Grid>
            </Grid>

            <MDButton
              disabled={button}
              onClick={submit}
              sx={{ mt: 4 }}
              size="small"
              fullWidth={true}
              variant="gradient"
              color="primary"
            >
              Create
            </MDButton>
          </Box>
        </Fade>
      </Modal>
      {/* <AlertMessage
        open={isOpen}
        // setOpen={setAlertOpen}
        severity={"Successfully"}
        message={"Added"}
      /> */}
      <AlertConfirm
        open={alertOpen}
        title="Create"
        context="Do you want to create an access region?"
        onClose={() => setAlertOpen(!alertOpen)}
        onAccept={() => {
          setAlertOpen(!alertOpen);
          const data = {
            country: country,
          };
          console.log("co", data);

          createLocation(data)
            .then((res) => {
              window.location.reload(false);
              return res.json();
            })
            .then((res) => {
              window.location.reload(false);
              handleClose();
            })
            .catch((err) => {
              if (err.response.status === 500) {
                alert("Country already exist");
              }
            });
        }}
      />
    </div>
  );
}
