import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Checkbox,
  TableCell,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import permissionsData from "./permissionsData";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { singleAdmin } from "services/api/gameAPI";
import { editAdmin } from "services/api/gameAPI";
import AlertConfirm from "components/AlertConfirm";
import { UN_AUTH_STATUS } from "lib/axiosConfig";

permissionsData.sort((a, b) => a.action.length - b.action.length);

let headerAction = [
  { name: "view", value: "read" },
  { name: "add", value: "write" },
  { name: "edit", value: "edit" },
  { name: "delete", value: "delete" },
  { name: "block", value: "block" },
];

function RoleBasedAccessControlEditUser() {
  const [userId, setUserId] = useState(null);
  let [alertOpen, setAlertOpen] = useState(false);
  const [roleName, setRoleName] = useState(null);
  const [userName, setUserName] = useState(null);
  let [newPassword, setNewPassword] = useState("");
  let [rePassword, setRePassword] = useState("");
  const [error, setError] = useState(false);
  // const [ids,setIds] = useState(null)
  const [permissions, setPermissions] = useState([]);
  const [Name, setName] = useState(null);
  const [loading, setLoading] = useState(true);

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [oldPermissionsData, setOldPermission] = useState([]);
  const fetchPermissionsData = async () => {
    try {
      setLoading(true);
      const response = await singleAdmin(id);
      const oldPermissionsData = response.data.permissions;
      setOldPermission(response.data);
      await setUserName(response.data.username);
      await setRoleName(response.data.role);
      const permissions = oldPermissionsData.map((permission) => {
        const [permissionName, action] = permission.split(".");
        return {
          permissionName,
          actions: [action],
        };
      });

      await setPermissions(permissions);
      console.log(permissions);
      setLoading(false);
    } catch (error) {
      alert(`${UN_AUTH_STATUS}`);
          window.history.back();
    }
  };

  useEffect(() => {
    fetchPermissionsData();
  }, []);
  const handlePermissionChange = (permissionName, action) => {
    setPermissions((prevPermissions) => {
      const existingPermissionIndex = prevPermissions.findIndex(
        (p) => p.permissionName === permissionName
      );

      if (existingPermissionIndex !== -1) {
        return prevPermissions
          .map((permission, index) => {
            if (permission.permissionName === permissionName) {
              const actions = permission.actions.includes(action)
                ? permission.actions.filter((a) => a !== action)
                : [...permission.actions.filter((a) => a !== action), action];

              if (actions.length === 0 && index !== 0) {
                console.log(action.length, index);
                // console.log("hello")
                return null;
              }

              return {
                ...permission,
                actions,
              };
            }

            return permission;
          })
          .filter(Boolean);
      } else {
        const newPermission = {
          permissionName,
          actions: [action],
        };

        return [...prevPermissions, newPermission];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== rePassword) {
      alert("Passwords do not match!");
      return;
    }
    if (!newPassword || !rePassword) {
      alert("Please fill in both password fields.");
      setError(true);
      return;
    }

    let payload = {
      username: userName,
      password: newPassword,
      role: roleName,
      permissions: [],
    };

    permissions.forEach((permission) => {
      permission.actions.forEach((action) => {
        const permissionText = `${permission.permissionName}.${action}`;
        payload.permissions.push(permissionText.trim());
      });
    });
    await setUserId(payload);
    setAlertOpen(true);
    
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}

      <div style={{ display: "flex", flexDirection: "column" }}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px" }}>
                <div>
                  <label style={{color:"white", margin: "12px" }}>Username</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    defaultValue={userName}
                    placeholder="Input role name"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                  <label style={{color:"white", margin: "12px" }}> New Password</label>
                  <TextField
                    variant="outlined"
                    style={{ marginBottom: "12px" }}
                    type={showPassword ? "text" : "password"}
                    defaultValue={newPassword}
                    placeholder="Input password name"
                    onChange={(e) => setNewPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                  <label style={{color:"white", margin: "12px" }}>Confirm Password</label>
                  <TextField
                    variant="outlined"
                    style={{ marginBottom: "12px" }}
                    type={showPassword ? "text" : "password"}
                    defaultValue={rePassword}
                    placeholder="Input password name"
                    onChange={(e) => setRePassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    required
                  />
                  {error && <p style={{ color: "red" }}>Please fill in both password fields.</p>}
                  <label style={{ color:"white", margin: "12px" }}>Role name</label>
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    defaultValue={roleName}
                    placeholder="Input role name"
                    onChange={(e) => setRoleName(e.target.value)}
                    helperText={
                      <div style={{color:"white"}}>
                      Double-click to uncheck the value.
                      <br />
                    </div>
                    }
                  />
                </div>
              </div>

              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "40px 0px",
                }}
              >
                <Table>
                  <TableHead style={{ display: "table-header-group" }}>
                    <TableRow>
                      <TableCell style={{ color: "white" }}> Functionalities/Name</TableCell>

                      {headerAction.map((e) => (
                        <TableCell style={{ color: "white" }} key={e?.name}>
                          <p>{e.name && e.name.charAt(0).toUpperCase() + e.name.slice(1)}</p>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {permissionsData.map((p) => (
                      <TableRow key={p._id}>
                        <TableCell  style={{ color: "white" }}>{p.name}</TableCell>
                        {headerAction?.map((e) => (
                          <TableCell key={`${p._id}-${e.name}-${e.value}`}>
                            <div
                              key={`${p._id}-${e.name}-${e.value}`}
                              style={{ display: p.action.includes(e.value) ? "flex" : "none" }}
                            >
                              <Checkbox
                                checked={
                                  permissions?.findIndex(
                                    (pe) =>
                                      pe.permissionName === p.name && pe.actions.includes(e.value)
                                  ) !== -1
                                }
                                onChange={() => handlePermissionChange(p.name, e.value)}
                              />
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ margin: "20px" }}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        )}
        <AlertConfirm
          open={alertOpen}
          title="Delete"
          context="Do you want to Edit ?"
          onClose={() => setAlertOpen(!alertOpen)}
          onAccept={async () => {
            try {
              const data = await editAdmin(id, userId);
              console.log(data);
              alert("Successfully updated");
              navigate("/WebUser");
            } catch (error) {
              console.error("Error:", error);
              alert("Update failed.");
            }
          }}
        />
      </div>
    </DashboardLayout>
  );
}

export default RoleBasedAccessControlEditUser;
