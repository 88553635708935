import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
// import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";

import { Button, Card, Grid, Switch, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import MDBox from "components/MDBox";
import AlertConfirm from "components/AlertConfirm";
import AlertMessage from "components/AlertMessage";
import { createBlockChianConfigPaymentMethod } from "services/api/gameAPI";

const style = {
  position: "absolute",
  top: { xs: "25%", md: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, sm: 700, lg: 800 },
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  height: { xs: 400, md: 230 },
  overflowY: "scroll",
};

const label = {
  inputProps: { "aria-label": "Color switch demo" },
};

export default function EditModalBox({ configId, symbol, contractAddress, isEnabled }) {
  const [isAlertMessgOpen, setIsAlertMessg] = React.useState(false);

  const [alertOpen, setAlertOpen] = React.useState(false);
  const submit = () => {
    setAlertOpen(true);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = async () => {
    try {
      setOpen(true);
    } catch (error) {
      // Handle errors here

      console.error("Error fetching data:", error);
    }
  };
  const handleClose = () => setOpen(false);

  const [newSymbol, setNewSymbol] = React.useState(symbol);
  const [newContractAddress, setNewContractAddress] = React.useState(contractAddress);
  const [isEnable, setIsEnable] = React.useState(isEnabled);

  const [data, setData] = React.useState();

  React.useEffect(() => {
    setData({
      contractAddress: newContractAddress,
      symbol: newSymbol,
      isEnable: isEnable,
    });
  }, [newSymbol, newContractAddress, isEnable]);

  return (
    <>
      <Button
        variant="contained"
        sx={{ marginBottom: "20px", textTransform: "none" }}
        onClick={handleOpen}
      >
        Edit
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Box container sx={style.modal}>
            <Box sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "20px",
                      padding: 3,
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{ m: 3 }}
                        textAlign="center"
                        id="transition-modal-title"
                        variant="h4"
                        component="h2"
                      >
                        Edit Token
                      </Typography>

                      <MDBox>
                        <Stack
                          width={"100%"}
                          direction={{ xs: "column", md: "row" }}
                          marginBottom={3}
                          gap={2}
                          justifyContent={"start"}
                        >
                          <TextField
                            value={newSymbol}
                            type="symbol"
                            onChange={(e) => setNewSymbol(e.target.value)}
                            label="Symbol"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                          <TextField
                            value={newContractAddress}
                            onChange={(e) => setNewContractAddress(e.target.value)}
                            label="Contract address"
                            sx={{ width: { md: 400 } }}
                          />

                          <Stack direction={"row"} alignItems={"center"}>
                            <Typography variant="caption" color={"#fff"}>
                              Required
                            </Typography>
                            <Switch
                              {...label}
                              checked={isEnable}
                              color="warning"
                              onChange={() => setIsEnable(!isEnable)}
                            />
                          </Stack>

                          <MDButton
                            type="submit"
                            onClick={submit}
                            sx={{ textAlign: "center" }}
                            variant="gradient"
                            color="primary"
                          >
                            Edit
                          </MDButton>
                        </Stack>{" "}
                      </MDBox>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <AlertConfirm
              open={alertOpen}
              title="Create"
              context="Do you want to add Token ?"
              onClose={() => setAlertOpen(!alertOpen)}
              onAccept={async () => {
                try {
                  console.log(data);
                  const res = await createBlockChianConfigPaymentMethod(configId, data);
                  console.log(res);
                  setAlertOpen(false);
                  alert("Payment method updated successfully!");
                  setOpen(false);
                  setTimeout(() => {
                    window.location.reload();
                  }, 300);
                } catch (error) {
                  console.log(error);
                  alert("Something went wrong!");
                }
              }}
            />

            <AlertMessage
              open={isAlertMessgOpen}
              setOpen={setIsAlertMessg}
              severity="Error"
              message="Something went wrong!"
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
