import {
  Avatar,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getAllReferralDetails } from "services/api/gameAPI";
import BackButton from "components/BackButton/BackButton";
// import { CSVLink } from "react-csv";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
}));

const ReferralDetails = () => {
  const { id } = useParams();

  console.log(id);
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAllReferralDetails = async () => {
      setLoading(true);
      const response = await getAllReferralDetails({ id: id, page: page + 1, limit });
      console.log(response.data?.data.profilesJoined);
      setCount(response.data?.data?.totalCount);
      setUserList(response.data?.data?.profilesJoined);
      setLoading(false);
    };
    fetchAllReferralDetails();
  }, [limit, page, id]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  // const headers = [
  //   { label: "First Name", key: "firstname" },
  //   { label: "Last Name", key: "lastname" },
  //   { label: "Email", key: "email" },
  // ];

  // const data = [
  //   { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
  //   { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
  //   { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" },
  // ];

  return (
    <>
      {" "}
      <DashboardLayout>
        <BackButton />

        {loading ? (
          <div style={{ color: "#FFF", paddingLeft: "35%", paddingTop: "20%" }}>Loading...</div>
        ) : (
          <>
            <Grid container spacing={6} sx={{ marginTop: 3 }}>
              <Grid item xs={12}>
                <Card sx={{ padding: 2 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "12px -1px",
                    }}
                  >
                    <MDTypography
                      component="label"
                      style={{
                        padding: "40px",
                        color: "grey",
                        fontWeight: 600,
                      }}
                    >
                      Referral Details
                    </MDTypography>
                    {/* <CSVLink data={data} headers={headers}>
                      Download me
                    </CSVLink>
                    ; */}
                  </div>
                  {/* {referralList.length === 0 ? (
            <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>
              There are no records at the moment.
            </div>
          ) : ( */}
                  <>
                    <TableContainer>
                      <Table>
                        <StyledTableHead>
                          <TableRow>
                            <StyledTableCell>PROFILE PHOTO</StyledTableCell>
                            <StyledTableCell>FULL NAME</StyledTableCell>
                            <StyledTableCell>USERNAME</StyledTableCell>
                            <StyledTableCell>VISIT PROFILE</StyledTableCell>
                            {/* Add more table headers as needed */}
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {userList?.map((user, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell>
                                <Avatar variant="square" src={user?.profilePic} />
                              </StyledTableCell>
                              <StyledTableCell>{user?.name}</StyledTableCell>
                              <StyledTableCell>{user?.username}</StyledTableCell>
                              <StyledTableCell>
                                <Link to={`/profile/${user._id}`} state={{ List: user._id }}>
                                  <MDButton color="info">VIEW</MDButton>
                                </Link>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                  {/* )} */}
                </Card>
              </Grid>
              <Grid item xs={12}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={count}
                  style={{ color: "white" }}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                />
              </Grid>
            </Grid>
            <Footer />
          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default ReferralDetails;
