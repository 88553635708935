import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useContext } from "react";
import styled from "styled-components";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import { AppContext } from "context";
import { useMaterialUIController } from "context";
import { ethers } from "ethers";

const HiddenButton = styled.div`
  display: none;
  position: absolute;
  top: -10px;
  right: -8px;
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;
  background: #022737;
  padding: 10px;
  border-radius: 16px;
  border: 2px solid #11b1f4;
  display: flex;
  height: 50px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:hover ${HiddenButton} {
    display: block;
  }
`;

function BalanceViewer({ data, sendPolTransfer, sendUsdcTransfer }) {
  return (
    <>
      <Box
        sx={{
          position: "relative",
          background: " #022737",
          padding: "10px",
          borderRadius: "16px",
          border: "2px solid #11b1f4",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", minWidth: "180px" }}>
          <Typography style={{ fontSize: 10, color: "white" }}>{data?.title}</Typography>

          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ fontSize: 10, color: "white", paddingRight: 10 }}>{`Pol : ${
                data?.maticToken ?? 0
              } `}</Typography>
              {/* <Box onClick={sendPolTransfer}>
                <SwapHorizontalCircleIcon style={{ color: "white", cursor: "pointer" }} />
              </Box> */}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ fontSize: 10, color: "white", paddingRight: 10 }}>{`USDC : ${
                data?.usdcToken ?? 0
              } `}</Typography>
              {/* <Box onClick={sendUsdcTransfer}>
                <SwapHorizontalCircleIcon style={{ color: "white", cursor: "pointer" }} />
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

function AllBalanceViewer() {
  const [controller, dispatch] = useMaterialUIController();
  const { allAdminBal } = controller;

  //   console.log(allAdminBal, "");

  const data = allAdminBal?.data;
  const listData = [
    {
      title: "Admin wallet",
      ...data?.admin,
    },
    {
      title: "Feed wallet",
      ...data?.feed,
    },
    {
      title: "Prize wallet",
      ...data?.platform,
    },
    {
      title: "Bot tol amount",
      ...data?.botUser,
    },
  ];

  /*
  const transferPolToken = async (e) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = await provider.getSigner();
      const address = await signer.getAddress();

      console.log(address, e?.address);
      // Check if the sender and recipient addresses are the same
      if (e?.address === address) {
        return alert("The recipient's address cannot be the same as your own.");
      }

      const transaction = await signer.sendTransaction({
        to: e?.address,
        // value: ethers.utils.parseUnits("5", 18),
        gasLimit: 300000,
      });
    } catch (error) {
        // Handle specific error types
    if (error.code === 4001) {
      alert("Transaction rejected by user.");
    } else {
      alert("An error occurred during the transaction. Please try again.");
    }
      console.error(error);
    }
  };
*/
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        position: "relative",
        overflowX: "scroll",
        "&::-webkit-scrollbar": { display: "none" },
        alignItems: "center",
        marginTop: 1,
        width: {
          xs: "100%", //small screen
          sm: "40%",
          md: "40%", //tablet
          lg: "50%", // full screen
        },
        marginBottom: 2,
      }}
    >
      {listData.length > 0
        ? listData.map((e, index) => <BalanceViewer key={index} data={e} />)
        : null}{" "}
    </Box>
  );
}

export { BalanceViewer, AllBalanceViewer };
