import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
// import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import MDButton from "components/MDButton";

import { Button, Card, Grid, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import MDBox from "components/MDBox";
import AlertConfirm from "components/AlertConfirm";
import AlertMessage from "components/AlertMessage";
import { createFaq } from "services/api/gameAPI";

const style = {
  position: "absolute",
  top: { xs: "25%", md: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, sm: 700, lg: 800 },
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  height: { xs: 400, md: 400 },
  overflowY: "scroll",
};

export default function CreateModalBox() {
  const [isAlertMessgOpen, setIsAlertMessg] = React.useState(false);

  const [alertOpen, setAlertOpen] = React.useState(false);
  const submit = () => {
    setAlertOpen(true);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = async () => {
    try {
      setOpen(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleClose = () => setOpen(false);

  const [heading, setHeading] = React.useState("");
  const [summary, setSummary] = React.useState("");

  const [data, setData] = React.useState();

  React.useEffect(() => {
    setData({
      question: heading,
      answer: summary,
    });
  }, [heading, summary]);

  return (
    <>
      <Button
        variant="contained"
        sx={{ marginBottom: "20px", textTransform: "none" }}
        onClick={handleOpen}
      >
        Create
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Box container sx={style.modal}>
            <Box sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "20px",
                      padding: 3,
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{ m: 3 }}
                        textAlign="center"
                        id="transition-modal-title"
                        variant="h4"
                        component="h2"
                      >
                        Create Faq's
                      </Typography>

                      <MDBox>
                        <Stack
                          width={{ sm: "300px", md: "500px" }}
                          marginBottom={3}
                          gap={2}
                          justifyContent={"start"}
                        >
                          <TextField
                            value={heading}
                            type="heading"
                            onChange={(e) => setHeading(e.target.value)}
                            label="Heading"
                          />
                          <TextField
                            id="outlined-multiline-static"
                            label="Summary"
                            value={summary}
                            multiline
                            onChange={(e) => setSummary(e.target.value)}
                            rows={4}
                          />

                          <MDButton
                            type="submit"
                            onClick={submit}
                            sx={{ textAlign: "center" }}
                            variant="gradient"
                            color="primary"
                          >
                            Create
                          </MDButton>
                        </Stack>{" "}
                      </MDBox>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <AlertConfirm
              open={alertOpen}
              title="Create"
              context="Do you want to add Faq ?"
              onClose={() => setAlertOpen(!alertOpen)}
              onAccept={async () => {
                try {
                  console.log(data);
                  const res = await createFaq(data);
                  console.log(res);
                  setAlertOpen(false);
                  alert("Faq added successfully!");
                  setOpen(false);
                  setTimeout(() => {
                    window.location.reload();
                  }, 300);
                } catch (error) {
                  console.log(error);
                  alert("Something went wrong!");
                }
              }}
            />

            <AlertMessage
              open={isAlertMessgOpen}
              setOpen={setIsAlertMessg}
              severity="Error"
              message="Something went wrong!"
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
